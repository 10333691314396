import MainComponent from "./components/MainComponent";
import {BrowserRouter} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
      <BrowserRouter>
        <MainComponent />
      </BrowserRouter>
  );
}

export default App;

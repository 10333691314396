import ScreenTitle from "../common/ScreenTitle";
import {useEffect, useState} from "react";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import AlertModal from "../common/AlertModal";
import {API} from "../../network/API";
import LoadingSpinner from "../common/LoadingSpinner";

import "./GuideSortComponent.css";
import ArrayUtil from "../../util/ArrayUtil";

export const GuideSortComponent = (props) => {

    const [guides, setGuides] = useState([]);
    const [guidesNetworkInFlight, setGuideNetworkInFlight] = useState(false);

    const [dragTarget, setDragTarget] = useState(null);
    const [dropTarget, setDropTarget] = useState(null);

    useEffect(() => {
        fetchGuidesFromNetwork();
    }, []);

    function commitDrop() {
        console.log("COMMIT DROP!");

        if (dragTarget === null || dropTarget === null) {
            // Clear targets, await valid drop
            setDragTarget(null);
            setDropTarget(null);
            return;
        }

        let dragIndex = -1;
        let dropIndex = -1;

        for (let i = 0; i < guides.length; i++) {
            if (guides[i].id === dragTarget) {
                dragIndex = i;
            } else if (guides[i].id === dropTarget) {
                dropIndex = i;
            }

            if (dragIndex !== -1 && dropIndex !== -1) {
                break;
            }
        }

        if (dragIndex !== -1 && dropIndex !== -1) {
            let newGuides = ArrayUtil.arrayMoveImmutable(
                guides,
                dragIndex,
                dropIndex
            );

            setGuides(newGuides);
        }

        // Clear these now
        setDragTarget(null);
        setDropTarget(null);
    }

    function fetchGuidesFromNetwork() {
        if (guidesNetworkInFlight) return;
        setGuideNetworkInFlight(true);

        Axios.get(Endpoints.guide.getGuides)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setGuides(resp.data.data);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setGuideNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setGuideNetworkInFlight(false);
                AlertModal.showError("Could not fetch Guides. Please try again later. [GS1000C]");
            })
    }

    function submitGuideOrderingOverNetwork() {
        if (guidesNetworkInFlight) return;
        setGuideNetworkInFlight(true);

        let ids = [];
        for (let i = 0; i < guides.length; i++) {
            ids.push(guides[i].id);
        }

        let data = {
            ids : JSON.stringify(ids)
        };

        Axios.post(Endpoints.guide.submitGuideOrdering, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    AlertModal.showModal(
                        "Success",
                        "Guide ordering has been saved"
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setGuideNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setGuideNetworkInFlight(false);
                AlertModal.showError("An unknown error has occurred. Please try again later. [GS2000C]");
            })
    }

    let primaryContent = [];
    if (guidesNetworkInFlight) {
        primaryContent = (
            <div className={"text-center"}>
                <LoadingSpinner inline={true} />
            </div>
        );
    } else {
        primaryContent = guides.map((guide) => {
            let extraClass = "";
            if (guide.id === dropTarget) {
                extraClass = "drop-target";
            }

            return (
                <div className={"guide-item " + extraClass}
                     draggable={true}
                     onDrop={() => commitDrop()}
                     onDragStart={() => setDragTarget(guide.id)}
                     onDragEnd={() => commitDrop()}
                     onDragEnter={() => setDropTarget(guide.id)}
                     onDragExit={() => setDropTarget(null)}>

                    <div className={"card mb-2"}>
                        <div className={"card-body"}>
                            {guide.title}
                        </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className={"guide-sort-component"}>
            <div className={"container"}>
                <div className={"row screen-title-container"}>
                    <div className={"col-12 col-md-6"}>
                        <ScreenTitle title={"Sort Guides"} showBackButton={true} {...props} />
                    </div>
                    <div className={"col-12 col-md-6 screen-actions animated"}>
                        <button className={"btn btn-light"} onClick={() => submitGuideOrderingOverNetwork()}>Save Ordering</button>
                    </div>
                </div>

                <div className={"app-content animate-screen-did-appear"}>
                    <div className={"row justify-content-center"}>
                        <div className={"col-12 col-sm-8 col-md-6 col-lg-4"}>
                            {primaryContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
import React from 'react';

import './TableComponent.css';

import sortAsc from "../../assets/certsure/image/sort_asc.png";
import sortDesc from "../../assets/certsure/image/sort_desc.png";
import sortNone from "../../assets/certsure/image/sort_both.png";

class TableComponent extends React.Component {

    SORT_DIRECTION_NONE = "none";
    SORT_DIRECTION_ASCENDING = "asc";
    SORT_DIRECTION_DESCENDING = "desc";

    constructor(props, context) {
        super(props, context);

        var columns = [];

        this.state = {
            columns
        };
    }

    componentDidMount() {
        this.processColumns();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.children !== this.props.children) {
            this.processColumns();
        }
    }

    processColumns = () => {
        console.log("TABLE - PROCESS COLUMNS");

        let columns = [];

        if (this.props.children != null) {
            console.log("TABLE - HAS CHILDREN");
            React.Children.map(this.props.children, function(child) {
                console.log("TABLE - CHILD");
                console.log(child);
                if (child.type === Column) {
                    console.log("TABLE - CHILD CORRECT TYPE");
                    let columnDef = {
                        name : child.props.name,
                        title : child.props.title,
                        className : child.props.className
                    };

                    if (child.props.columnClassName !== undefined) {
                        columnDef.columnClassName = child.props.columnClassName;
                    }

                    if (child.props.render != null) {
                        columnDef.render = child.props.render;
                    }

                    if (child.props.hasOwnProperty("sortable")) {
                        columnDef.sortable = child.props.sortable;
                    }

                    if (child.props.customFormat !== undefined) {
                        columnDef.customFormat = child.props.customFormat;
                    }

                    columns.push(columnDef);
                }
            });
        } else if (this.props.columns != null) {
            columns = this.props.columns;
        }

        console.log(columns);

        this.setState({
            columns : columns
        });
    }

    handleClick(row) {
        if (this.props.onRowClick != null) {
            this.props.onRowClick(row);
        }
    }

    handleHeadingClick = (column) => {
        if (this.props.onSort != null) {
            if (column != null) {
                if (column.sortable) {
                    let currentSort = this.SORT_DIRECTION_NONE;

                    let sortedColumn = null;
                    if (this.props.hasOwnProperty("sortedColumn")) {
                        sortedColumn = this.props.sortedColumn;
                    }

                    if (sortedColumn != null) {
                        if (sortedColumn.name === column.name) {
                            currentSort = sortedColumn.direction;
                        }
                    }

                    if (currentSort === this.SORT_DIRECTION_ASCENDING) {
                        currentSort = this.SORT_DIRECTION_DESCENDING;
                    } else {
                        currentSort = this.SORT_DIRECTION_ASCENDING;
                    }

                    this.props.onSort({
                        name : column.name,
                        direction : currentSort
                    });
                }
            }
        }
    }

    render() {
        var headerColumns = [];
        var tableRows = [];

        let sortedColumn = null;
        if (this.props.hasOwnProperty("sortedColumn")) {
            sortedColumn = this.props.sortedColumn;
        }

        let showHeader = true;
        if (this.props.showHeader !== undefined) {
            showHeader = this.props.showHeader;
        }

        if (this.state.columns != null) {
            if (showHeader) {
                for (var i = 0; i < this.state.columns.length; i++) {
                    let column = this.state.columns[i];

                    let sortable = false;
                    if (column.hasOwnProperty("sortable")) {
                        sortable = column.sortable === true;
                    }

                    let sortDirection = this.SORT_DIRECTION_NONE;
                    if (sortedColumn != null) {
                        if (sortedColumn.name === column.name) {
                            sortDirection = sortedColumn.direction;
                        }
                    }

                    let columnHeaderClass = column.className;
                    let columnHeaderStyle = {};
                    if (sortable) {
                        if (columnHeaderClass === undefined || columnHeaderClass == null) {
                            columnHeaderClass = "";
                        } else {
                            columnHeaderClass += " ";
                        }

                        columnHeaderClass = "sortable";

                        if (sortDirection === this.SORT_DIRECTION_ASCENDING) {
                            columnHeaderStyle.backgroundImage = sortAsc;
                        } else if (sortDirection === this.SORT_DIRECTION_DESCENDING) {
                            columnHeaderStyle.backgroundImage = sortDesc;
                        } else {
                            columnHeaderStyle.backgroundImage = sortNone;
                        }
                    }

                    headerColumns.push(
                        <th key={"th_" + i} className={columnHeaderClass} style={columnHeaderStyle}
                            onClick={() => this.handleHeadingClick(column)}>
                            {column.title}
                        </th>
                    );
                }
            }

            if (this.props.data != null) {
                for (let i = 0; i < this.props.data.length; i++) {
                    let columns = [];

                    let data = this.props.data[i];

                    for (var x = 0; x < this.state.columns.length; x++) {
                        let column = this.state.columns[x];
                        let colValue = "";
                        let colData = null;

                        if (data.hasOwnProperty(column.name)) {
                            colData = data[column.name];
                            if (column.hasOwnProperty("render")) {
                                colValue = column.render(colData, data)
                            } else {
                                colValue = colData;
                            }
                        }

                        let classNameExtra = "";
                        if (column.columnClassName !== undefined) {
                            classNameExtra += column.columnClassName;
                        }

                        if (column.customFormat !== undefined) {
                            console.log("TABLE: Get custom format from callback");
                            classNameExtra = column.customFormat(colData, data, colValue, column);
                            console.log(classNameExtra);
                        }
                        if (classNameExtra !== "") {
                            classNameExtra = " " + classNameExtra;
                        }

                        columns.push(
                            <td key={"td_" + i + "_" + x} className={column.className + classNameExtra}>
                                { colValue }
                            </td>
                        );
                    }

                    tableRows.push(
                        <tr key={i} onClick={() => this.handleClick(data)}>
                            { columns }
                        </tr>
                    );
                }
            }
        }

        if (tableRows.length == 0) {
            var emptyClassName = "empty-message";
            if (this.props.emptyClassName != null) {
                emptyClassName = this.props.emptyClassName;
            }

            var emptyMessage = "No data to display";
            if (this.props.emptyMessage != null) {
                emptyMessage = this.props.emptyMessage;
            }

            tableRows.push(<tr>
                <td colSpan={headerColumns.length} className={emptyClassName}>{emptyMessage}</td>
            </tr>);
        }

        let tableClassName = "ep-table";
        if (this.props.className !== undefined && this.props.className != null) {
            tableClassName += " " + this.props.className;
        }

        let headerRow = [];
        if (showHeader) {
            headerRow = (
                <thead>
                <tr>
                    { headerColumns }
                </tr>
                </thead>
            );
        }

        return (
            <table className={tableClassName}>
                {headerRow}
                <tbody>
                { tableRows }
                </tbody>
            </table>
        );
    }

}

export default TableComponent;

class Column extends React.Component {

}

class TablePaginator extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.incrementPage = this.incrementPage.bind(this);
        this.decrementPage = this.decrementPage.bind(this);
    }

    handleClick(index) {
        if (this.props.onClick != null) {
            this.props.onClick(index);
        }
    };

    incrementPage() {
        var totalCount = 0;
        if (this.props.totalCount != null) {
            totalCount = Math.max(0, this.props.totalCount);
        }

        var pageSize = 10;
        if (this.props.pageSize != null) {
            pageSize = Math.max(1, this.props.pageSize);
        }

        var maxPages = parseInt(Math.ceil(totalCount / pageSize));
        var nextPage = parseInt(Math.min(maxPages, this.props.page + 1));

        this.handleClick(nextPage);
    }

    decrementPage() {
        var nextPage = Math.max(1, this.props.page - 1);
        this.handleClick(nextPage);
    }

    render() {
        var totalCount = 0;
        if (this.props.totalCount != null) {
            totalCount = Math.max(0, this.props.totalCount);
        }

        var pageSize = 10;
        if (this.props.pageSize != null) {
            pageSize = Math.max(1, this.props.pageSize);
        }

        var page = 1;
        if (this.props.page != null) {
            page = Math.max(1, this.props.page);
        }

        var maxItems = 10;
        if (this.props.maxItems != null) {
            maxItems = this.props.maxItems;
        }

        var items = [];

        items.push(<span className={"page-prev"} onClick={this.decrementPage}>&#9664;</span>);

        var totalPages = parseInt(Math.ceil(totalCount / pageSize));
        totalPages = Math.max(totalPages, 1);
        if (totalPages > maxItems) {
            var halfPage = maxItems / 2;

            var insertPosition = false;
            var countTo = halfPage;
            var backendCount = (totalPages - (halfPage - 1));
            if (page > halfPage && (page <= backendCount)) {
                halfPage--;
                insertPosition = true;
            }

            for (let i = 0; i < halfPage; i++) {
                var extraClass = "";
                if (page === (i + 1)) {
                    extraClass = " active";
                }
                items.push(<span className={"page-item" + extraClass} onClick={() => this.handleClick((i + 1))}>{(i + 1)}</span>);
            }

            if (insertPosition) {
                items.push(<span className={"page-gap"}>&#8230;</span>);
                items.push(<span className={"page-item active"} onClick={() => this.handleClick(page)}>{page}</span> );
            }
            items.push(<span className={"page-gap"}>&#8230;</span>);

            for (let i = backendCount; i < totalPages; i++) {
                var extraClass = "";
                if (page === (i + 1)) {
                    extraClass = " active";
                }
                items.push(<span className={"page-item" + extraClass} onClick={() => this.handleClick((i + 1))}>{(i + 1)}</span>);
            }
        } else {
            for (let i = 0; i < totalPages; i++) {
                var extraClass = "";
                if (page === (i + 1)) {
                    extraClass = " active";
                }
                items.push(<span className={"page-item" + extraClass} onClick={() => this.handleClick((i + 1))}>{(i + 1)}</span>);
            }
        }

        items.push(<span className={"page-next"} onClick={this.incrementPage}>&#9658;</span>);

        var out = (
            <span className={"ep-table-paginate"}>
                {items}
            </span>
        );

        return out;
    };

}

export { Column, TablePaginator };
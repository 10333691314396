import ScreenTitle from "../common/ScreenTitle";
import {createRef, useEffect, useState} from "react";
import Validator from "../../util/Validator";
import AlertModal from "../common/AlertModal";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import {API} from "../../network/API";

export const LinkEditorComponent = (props) => {

    const {typeId} = props.match.params;
    const {id} = props.match.params;

    const [link, setLink] = useState(null);
    const [linkTitle, setLinkTitle] = useState(null);
    const [linkUrl, setLinkUrl] = useState(null);
    const [linkSplines, setLinkSplines] = useState(0);

    const [linkThumbnailContent, setLinkThumbnailContent] = useState(null);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [iconFile, setIconFile] = useState(null);

    const iconFileInput = createRef();

    useEffect(() => {
        if (id && id !== "new") {
            fetchLinkOverNetwork();
        }
    }, []);

    useEffect(() => {
        if (link) {
            setLinkTitle(link.title);
            setLinkUrl(link.url);
            setLinkThumbnailContent(process.env.REACT_APP_BASE_URL + link.iconPath);
        }
    }, [link]);

    function iconFileWasRequested() {
        if (iconFileInput.current) {
            iconFileInput.current.click();
        }
    }

    function iconFileDidChange(e) {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];

            setIconFile(file);

            const reader = new FileReader();
            reader.onload = () => {
                setLinkThumbnailContent(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    function fetchLinkOverNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let data = {
            id,
            typeId,
            page : 1,
            pageSize : 1
        };

        Axios.post(Endpoints.more.getMoreLinkItems, data)
            .then((r) => {
                let error = null;

                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        const source = resp.data.data[0];
                        if (source.moreItemLinks.data.length > 0) {
                            setLink(source.moreItemLinks.data[0]);
                        } else {
                            error = "Could not find Link";
                        }
                    } else {
                        error = "Could not find Link";
                    }
                } else {
                    error = API.formatError(resp);
                }

                if (error !== null) {
                    AlertModal.showError(error);
                }

                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError("An unknown error has occurred. [LEE1000C]");
            })
    }

    function submitLinkOverNetwork() {
        if (networkInFlight) return;

        const validationResult = Validator.validateCreateFormData({
            id : link ? link.id : undefined,
            typeId, linkTitle, linkUrl
        }, [
            Validator.rule("id", "int", "", "id", true),
            Validator.rule("typeId", "int", "Type ID", "typeId"),
            Validator.rule("linkTitle", "string", "Title", "title"),
            Validator.rule("linkUrl", "string", "Link URL", "url")
        ]);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        setNetworkInFlight(true);

        let formData = validationResult.formData;

        if (iconFile !== null) {
            formData.append("iconFile", iconFile);
        }

        Axios.post(Endpoints.more.submitMoreLinkItem, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setLink(resp.data.moreLinkItem);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError("An unknown error has occurred. [MLS1000C]");
            })
    }

    // RENDER

    let primaryContent = [];
    if (!networkInFlight) {
        primaryContent = (
            <>
                <div className={"row"}>

                    <div className={"col-12 col-md-6"}>
                        <div className={"row"}>
                            <div className={"col-12 mt-2"}>
                                <label>Title</label>
                                <input type={"text"} className={"form-control"} value={linkTitle} onChange={(e) => setLinkTitle(e.target.value)} />
                            </div>

                            <div className={"col-12 mt-2"}>
                                <label>Link URL</label>
                                <input type={"text"} className={"form-control"} value={linkUrl} onChange={(e) => setLinkUrl(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <div className={"row justify-content-center"}>
                            <div className={"col-12 col-md-6"}>
                                <label>Icon</label>
                                <div className={"ratio ratio-1x1 image-preview mt-2"} style={{backgroundImage : "url(" + linkThumbnailContent + ")"}} />
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12 text-center"}>
                                <button className={"btn btn-light"} onClick={() => iconFileWasRequested()}>Select Image</button>
                                <div className={"file-hide"}>
                                    <input type={"file"} ref={iconFileInput} accept={"image/*"} onChange={iconFileDidChange} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn btn-light"} onClick={() => submitLinkOverNetwork()}>Save</button>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={"news-list-component"}>
            <div className={"container"}>
                <div className={"row screen-title-container"}>
                    <div className={"col-12"} onClick={() => setLinkSplines(linkSplines + 1)}>
                        <ScreenTitle title={(linkSplines < 20) ? "Link" : "Zelda"} showBackButton={true} {...props} />
                    </div>
                </div>

                <div className={"app-content animate-screen-did-appear"}>
                    {primaryContent}
                </div>
            </div>
        </div>
    )

}
import React from 'react';
import LoadingSpinner from "./LoadingSpinner";

import "./UIBlocker.css";

export default class UIBlocker extends React.Component {

    render() {
        let shown = this.props.shown;
        if (shown === undefined) {
            shown = true;
        }

        if (!shown) return [];

        return (
            <div className={"ui-blocker"}>
                <div className={"text-center"}>
                    <LoadingSpinner />
                </div>
            </div>
        )

    }
}
import ScreenTitle from "../common/ScreenTitle";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import {useEffect, useState} from "react";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import AlertModal from "../common/AlertModal";
import {API} from "../../network/API";
import {GuideCategoryEditorModal} from "./GuideCategoryEditorModal";
import {BaseModalActions} from "../common/BaseModal";

export const GuideCategoriesComponent = (props) => {

    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [page, setPage] = useState(0);

    const [editorShown, setEditorShown] = useState(false);
    const [editorId, setEditorId] = useState(null);

    const [filterActive, setFilterActive] = useState("0");
    const [filterKeyword, setFilterKeyword] = useState("");

    // SETUP

    useEffect(() => {
        fetchCategoriesFromNetwork();
    }, []);

    useEffect(() => {
        fetchCategoriesFromNetwork();
    }, [filterActive, page, filterKeyword]);

    // METHODS

    function moveToEditor(id) {
        setEditorId(id);
        setEditorShown(true);
    }

    function tableRowWasClicked(row) {
        moveToEditor(row.id);
    }

    function editorDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            if (data) {
                fetchCategoriesFromNetwork();
            }
            setEditorShown(false);
        }
    }

    function fetchCategoriesFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const data = {
            activeOnly : filterActive
        };

        Axios.post(Endpoints.guide.getCategories, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setTableData(resp.data.data);
                    setTotalCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError("An unknown error has occurred. [G1000C]");
            });
    }

    // RENDER

    let emptyMessage = "No Categories to display";
    if (networkInFlight) {
        emptyMessage = "Loading...";
    }

    return (
        <div className={"vendors-landing-component"}>
            <div className={"container"}>
                <div className={"row screen-title-container"}>
                    <div className={"col-12 col-md-6"}>
                        <ScreenTitle title={"Categories"} showBackButton={true} {...props} />
                    </div>
                    <div className={"col-12 col-md-6 screen-actions animated"}>
                        <button className={"btn btn-light"} onClick={() => moveToEditor()}>Add Category</button>
                    </div>
                </div>

                <div className={"app-content animate-screen-did-appear"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-4"}>
                            <label>Show only active</label>
                            <select className={"form-select"} value={filterActive} onChange={(e) => setFilterActive(e.target.value)}>
                                <option value={"1"}>Active Only</option>
                                <option value={"0"}>All Categories</option>
                            </select>
                        </div>
                    </div>

                    <div className={"row mt-2"}>
                        <div className={"col-12"}>

                            <TableComponent
                                className={"alternating clickable small-table"}
                                data={tableData}
                                onRowClick={tableRowWasClicked}
                                emptyMessage={emptyMessage}>

                                <Column name={"id"} title={"ID"} />
                                <Column name={"categoryName"} title={"Category Name"} />
                                <Column name={"active"} title={"Active"} className={"text-center"} render={(data, row) => {
                                    let className = "bg-success";
                                    let label = "Active";

                                    if (parseInt(data) === 0) {
                                        className = "bg-secondary";
                                        label = "Inactive";
                                    }

                                    return (
                                        <span className={"badge " + className}>{label}</span>
                                    )
                                }}/>
                                <Column name={"id"} className={"text-end"} render={(data, row) => (
                                    <span className={"btn btn-light"}>View</span>
                                )} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={totalCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <GuideCategoryEditorModal
                shown={editorShown}
                categoryId={editorId}
                callback={editorDidCallback} />
        </div>
    )

}
import ScreenTitle from "../common/ScreenTitle";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import {useEffect, useState} from "react";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import AlertModal from "../common/AlertModal";
import {API} from "../../network/API";

export const NewsListComponent = (props) => {

    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [page, setPage] = useState(1);

    const [activeOnly, setActiveOnly] = useState("0");
    const [published, setPublished] = useState("0");

    useEffect(() => {
        fetchArticlesFromNetwork();
    }, []);

    useEffect(() => {
        fetchArticlesFromNetwork();
    }, [page, activeOnly, published]);

    function moveToEditor(id) {
        if (id === undefined) {
            id = "new";
        }

        if (props.history) {
            props.history.push("/news-article/" + id);
        }
    }

    function tableRowWasClicked(row) {
        moveToEditor(row.id);
    }

    function fetchArticlesFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let data = {
            page,
            activeOnly
        };

        if (published !== "-1") {
            data.published = published;
        }

        Axios.post(Endpoints.more.getLatestNewsItems, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setTableData(resp.data.data);
                    setTotalCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError("An unknown error has occurred. Please try again later. [LN1000C]");
            })
    }


    // RENDER

    let emptyMessage = "No Articles to display";
    if (networkInFlight) {
        emptyMessage = "Loading...";
    }

    return (
        <div className={"news-list-component"}>
            <div className={"container"}>
                <div className={"row screen-title-container"}>
                    <div className={"col-12 col-md-6"}>
                        <ScreenTitle title={"Latest News"} showBackButton={true} {...props} />
                    </div>
                    <div className={"col-12 col-md-6 screen-actions animated"}>
                        <button className={"btn btn-light"} onClick={() => moveToEditor()}>New Article</button>
                    </div>
                </div>

                <div className={"app-content animate-screen-did-appear"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-4"}>
                            <label>Active Status</label>
                            <select className={"form-select"} value={activeOnly} onChange={(e) => setActiveOnly(e.target.value)}>
                                <option value={"0"}>All Articles</option>
                                <option value={"1"}>Active Only</option>
                            </select>
                        </div>

                        <div className={"col-12 col-md-4"}>
                            <label>Published Status</label>
                            <select className={"form-select"} value={published} onChange={(e) => setPublished(e.target.value)}>
                                <option value={"0"}>All Articles</option>
                                <option value={"1"}>Published Only</option>
                            </select>
                        </div>
                    </div>

                    <div className={"row mt-2"}>
                        <div className={"col-12"}>

                            <TableComponent
                                className={"alternating clickable small-table"}
                                data={tableData}
                                onRowClick={tableRowWasClicked}
                                emptyMessage={emptyMessage}>

                                <Column name={"title"} title={"Title"} />
                                <Column name={"published"} title={"Published"} className={"text-center"} render={(data, row) => {
                                    let className = "bg-success";
                                    let label = "Published";

                                    if (parseInt(data) === 0) {
                                        className = "bg-secondary";
                                        label = "Unpublished";
                                    }

                                    return (
                                        <span className={"badge " + className}>{label}</span>
                                    )
                                }} />
                                <Column name={"publishDate"} className={"text-center"} title={"Published On"} />
                                <Column name={"active"} title={"Active"} className={"text-center"} render={(data, row) => {
                                    let className = "bg-success";
                                    let label = "Active";

                                    if (parseInt(data) === 0) {
                                        className = "bg-secondary";
                                        label = "Inactive";
                                    }

                                    return (
                                        <span className={"badge " + className}>{label}</span>
                                    )
                                }}/>
                                <Column name={"id"} className={"text-end"} render={(data, row) => (
                                    <span className={"btn btn-light"}>View</span>
                                )} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={totalCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default class Validator {
    /**
     * Validate an Object and create Form Data from it
     *
     * @param inObj         Object to validate
     * @param ruleArray     Rules to validate against
     * @returns {{success: boolean}}        Validation Result Bundle
     */
    static validateCreateFormData(inObj, ruleArray) {
        var outBundle = {
            success : true
        };

        var formData = new FormData();

        for (var i = 0; i < ruleArray.length; i++) {
            let rules = ruleArray[i];

            let keyName = rules.key;
            let type = rules.type;
            let label = rules.label;
            let postName = rules.postName;
            let optional = rules.optional;
            let defaultValue = rules.default;

            if (keyName === undefined) {
                console.log("Validation rule was passed with no key. Skipping.");
                continue;
            }

            if (type === undefined) {
                type = "string";
            }

            if (optional === undefined) {
                optional = false;
            }

            if (inObj.hasOwnProperty(keyName)) {
                var valid = true;
                var invalidReason = "";

                var value = inObj[keyName];
                if (value === undefined && defaultValue !== undefined) {
                    value = defaultValue;
                }

                if (type !== undefined) {
                    if (type === "int" || type === "integer" || type === "float" || type === "double") {
                        if (type === "int" || type === "integer") {
                            value = parseInt(value);
                        } else if (type === "float" || type === "double") {
                            value = parseFloat(value);
                        }

                        if (isNaN(value)) {
                            valid = false;
                            invalidReason = "Please provide a valid number for " + label;
                        }

                        if (valid && rules.hasOwnProperty("greaterZero")) {
                            if (rules.greaterZero) {
                                if (value <= 0) {
                                    valid = false;
                                    invalidReason = "Please provide a number greater than zero for " + label;
                                }
                            }
                        }

                        if (valid && rules.hasOwnProperty("greaterEqualsZero")) {
                            if (rules.greaterEqualsZero) {
                                if (value < 0) {
                                    valid = false;
                                    invalidReason = "Please provide a number that is greater or equal to zero for " + label;
                                }
                            }
                        }
                    } else if (type === "string") {
                        var notEmpty = true;
                        if (rules.hasOwnProperty("notEmpty")) {
                            notEmpty = rules.notEmpty
                        }

                        if (notEmpty) {
                            if (value === "" || value == null) {
                                valid = false;
                                invalidReason = "Please provide a value for " + label;
                            }
                        }
                    } else if (type === "boolean") {
                        if (value) {
                            value = 1;
                        } else {
                            value = 0;
                        }
                    } else if (type === "json") {
                        value = JSON.stringify(value);
                    }
                }

                if (valid) {
                    if (postName !== undefined) {
                        formData.append(postName, value);
                    } else {
                        formData.append(keyName, value);
                    }
                } else if (!optional) {
                    outBundle.success = false;
                    outBundle.error = invalidReason;
                    break;
                }
            } else if (!optional) {
                if (defaultValue !== undefined && defaultValue != null && postName !== undefined) {
                    formData.append(postName, defaultValue);
                } else {
                    outBundle.success = false;
                    outBundle.error = "Please provide a value for " + label;
                    break;
                }
            }
        }

        if (outBundle.success) {
            outBundle.formData = formData;
        }

        return outBundle;
    }

    static rule(key, type, label, postName, optional) {
        if (postName === undefined) {
            postName = key;
        }

        if (optional === undefined) {
            optional = false;
        }

        return { key, type, label, postName, optional };
    }

}
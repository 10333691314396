import {Route, Switch} from "react-router-dom";
import BaseComponent from "./BaseComponent";
import {DataManager} from "../data/DataManager";
import {API} from "../network/API";
import AlertModal from "./common/AlertModal";
import HeaderComponent from "./common/HeaderComponent";
import UsersComponent from "./user/UsersComponent";
import UserEditorComponent from "./user/UserEditorComponent";
import MessagesComponent from "./messages/MessagesComponent";
import LoginComponent from "./login/LoginComponent";
import GuidesComponent from "./guide/GuidesComponent";
import GuideEditorComponent from "./guide/GuideEditorComponent";
import {GuideCategoriesComponent} from "./guide/GuideCategoriesComponent";
import {GuideSortComponent} from "./guide/GuideSortComponent";
import {NewsListComponent} from "./news/NewsListComponent";
import {NewsEditorComponent} from "./news/NewsEditorComponent";
import {LinkItemListComponent} from "./links/LinkItemListComponent";
import {LinkEditorComponent} from "./links/LinkEditorComponent";
import {LinkSortComponent} from "./links/LinkSortComponent";

export default class MainComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let authToken = DataManager.getAuthenticationToken();
        if (authToken !== null) {
            this.onAuthTokenChanged(authToken);
        }

        this.initState({
            alertModalOpen : false,
            alertModalIcon : undefined,
            alertModalTitle : undefined,
            alertModalMessage : undefined,
            alertModalButtons : undefined
        });

        DataManager.addCallback((keyName, value) => {
            if (keyName === DataManager.KEYS.AUTHENTICATION_TOKEN) {
                if (this.state.authToken !== value) {
                    this.onAuthTokenChanged(value);
                }
            }
        });

        API.onAuthFailed(() => {
            DataManager.setAuthenticationToken(null);
        });
    }

    componentDidMount() {
        // Check for Authentication token
        let authToken = DataManager.getAuthenticationToken();
        if (authToken !== null) {
            this.onAuthTokenChanged(authToken);
        }

        window._sessionsSignOut = () => {
            DataManager.clear();
            window.location.reload();
        }

        window._showModal = (title, message, buttons, icon) => {
            this.showModal(title, message, buttons, icon);
        }

        window._hideModal = () => {
            this.hideModal();
        }
    }

    onAuthTokenChanged = (token) => {
        API.setAuthenticationToken(token);

        this.setState({
            authToken : token
        });
    }

    showModal = (title, message, buttons, icon) => {
        this.setState({
            alertModalOpen : true,
            alertModalIcon : icon,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        });
    }

    hideModal = () => {
        this.setState({
            alertModalOpen : false
        });
    }

    render() {
        let alertModal = (
            <AlertModal
                open={this.state.alertModalOpen}
                icon={this.state.alertModalIcon}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
                buttons={this.state.alertModalButtons}
                dismissCallback={this.hideModal} />
        );

        if (this.state.authToken === undefined || this.state.authToken === null) {
            return (
                <div>
                    <LoginComponent />
                    {alertModal}
                </div>
            )
        } else {
            return (
                <div>
                    <HeaderComponent/>

                    <Switch>
                        <Route
                            path={"/"} exact
                            render={(props) =>
                                <GuidesComponent {...props} />
                            } />
                        <Route
                            path={"/users"} exact
                            render={(props) =>
                                <UsersComponent {...props} />
                            } />

                        <Route
                            path={"/users/:id?"}
                            render={(props) =>
                                <UserEditorComponent {...props} />
                            } />

                        <Route
                            path={"/message/"}
                            render={(props) =>
                                <MessagesComponent {...props} />
                            } />

                        <Route
                            path={"/guides"} exact
                            render={(props) =>
                                <GuidesComponent {...props} />
                            } />

                        <Route
                            path={"/guide/:id?"}
                            render={(props) =>
                                <GuideEditorComponent {...props} />
                            } />

                        <Route
                            path={"/categories"}
                            render={(props) =>
                                <GuideCategoriesComponent {...props} />
                            } />

                        <Route
                            path={"/guide-sort"}
                            render={(props) =>
                                <GuideSortComponent {...props} />
                            } />

                        <Route
                            path={"/news"}
                            render={(props) =>
                                <NewsListComponent {...props} />
                            } />

                        <Route
                            path={"/news-article/:id"}
                            render={(props) =>
                                <NewsEditorComponent {...props} />
                            } />

                        <Route
                            path={"/links/:id?"}
                            render={(props) =>
                                <LinkItemListComponent {...props} />
                            } />

                        <Route
                            path={"/link/:typeId/:id"}
                            render={(props) =>
                                <LinkEditorComponent {...props} />
                            } />

                        <Route
                            path={"/links-sort/:typeId?"}
                            render={(props) =>
                                <LinkSortComponent {...props} />
                            } />

                    </Switch>

                    {alertModal}

                </div>
            )
        }
    }
}
import BaseComponent from "../BaseComponent";
import ScreenTitle from "../common/ScreenTitle";
import AlertModal from "../common/AlertModal";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import {API} from "../../network/API";
import UIBlocker from "../common/UIBlocker";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import LoadingSpinner from "../common/LoadingSpinner";

export default class MessagesComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            messageNetworkInFlight : false,
            messageTitle : "",
            messageBody : "",
            tableData : [],
            currentPage : 1,
            tableLimit : 20,
            tableTotal : 0,
            tableKeyword : ""
        });
    }

    componentDidMount() {
        this.fetchMessagesFromNetwork();
    }

    fetchMessagesFromNetwork = () => {
        if (this.state.usersNetworkInFlight) return;
        this.setState({
            usersNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", this.state.currentPage);
        formData.append("limit", this.state.tableLimit);

        Axios.post(Endpoints.messaging.getMessages, formData)
            .then((r) => {
                let tableData = [];
                let tableTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    tableData = resp.data.data;
                    tableTotal = resp.data.count;
                } else {
                    AlertModal.showModal("Error", API.formatError(resp));
                }

                this.setState({
                    usersNetworkInFlight : false,
                    tableData,
                    tableTotal
                });
            })
            .catch((e) => {
                this.setState({
                    usersNetworkInFlight : false
                });

                AlertModal.showModal("Error", "An unknown error has occurred. Please reload the page. [DU1077]");
            });
    }


    sendMessage = () => {
        if (this.state.messageNetworkInFlight) return;

        let validationResult = this.validateCreateFormData(this.state, [
            { key : "messageTitle", type : "string", label : "Message Title", required : true, postName : "title" },
            { key : "messageBody", type : "string", label : "Message Body", required : true, postName: "message" }
        ]);

        if (!validationResult.success) {
            AlertModal.showModal("Error", validationResult.error);
            return;
        }

        this.setState({
            messageNetworkInFlight : true
        });

        Axios.post(Endpoints.messaging.broadcastMessage, validationResult.formData)
            .then((r) => {
                let resp = API.parse(r);

                if (resp.success) {
                    AlertModal.showModal("Success", "Your message has been successfully sent to all applicable users.");
                } else {
                    AlertModal.showModal("Error", API.formatError(resp));
                }

                this.setState({
                    messageNetworkInFlight : false,
                    sendingNew : false,
                    messageTitle : "",
                    messageBody : "",
                },() => this.fetchMessagesFromNetwork());
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showModal("Error", "An unknown error has occurred. Please try again later.");
                this.setState({
                    messageNetworkInFlight : false,
                    sendingNew : false
                });
            });
    }


    newMessageMode = () => {
        this.setState({sendingNew : true})
    }

    cancelMessage = () => {
        this.setState({sendingNew : false,   messageTitle : "",
            messageBody : ""})
    }

    render() {
        let emptyMessage = undefined;
        if (this.state.usersNetworkInFlight) {
            emptyMessage = (<LoadingSpinner shown={true} />);
        }

        return (
            <div className={"vendors-landing-component"}>
                <div className={"container"}>

                    <div className={"row screen-title-container"}>
                        <div className={"col-12 col-md-6"}>
                            <ScreenTitle title={"Messages"} showBackButton={false} {...this.props} />
                        </div>
                        <div className={"col-12 col-md-6 screen-actions animated"}>
                            {!this.state.sendingNew && <button className={"btn btn-light"} onClick={() => this.newMessageMode()}>Send New Message
                            </button>}
                        </div>
                    </div>
                    {this.state.sendingNew &&

                            <div className={"app-content animate-screen-did-appear"}>
                                <div className={"row justify-content-center"}>
                                    <div className={"col-12 col-md-8 col-lg-6"}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <h2>Send Marketing Message</h2>
                                                <em>Send a message to all active devices.</em>
                                            </div>
                                        </div>

                                        <div className={"row mt-4"}>
                                            <div className={"col-12"}>
                                                <label>Message Title</label>
                                                <input type={"text"} className={"form-control"} name={"messageTitle"} value={this.state.messageTitle} onChange={this.handleChange} />
                                            </div>
                                        </div>

                                        <div className={"row mt-2"}>
                                            <div className={"col-12"}>
                                                <label>Message Text</label>
                                                <textarea className={"form-control"} name={"messageBody"} value={this.state.messageBody} onChange={this.handleChange} />
                                            </div>
                                        </div>

                                        <div className={"row mt-2"}>
                                            <div className={"col-12"}>
                                                <button className={"btn btn-success"} onClick={() => this.sendMessage()}>Send Message</button>

                                                <button className={"btn btn-success"} onClick={() => this.cancelMessage()} style={{marginLeft: "35px", background : "#999999"}}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }


                    {!this.state.sendingNew  &&
                        <div className={"app-content animate-screen-did-appear"}>
                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <TableComponent
                                        className={"alternating small-table"}
                                        data={this.state.tableData}
                                        onRowClick={this.tableRowWasClicked}
                                        emptyMessage={emptyMessage}>
                                        <Column name={"messageTitle"} title={"Title"}/>
                                        <Column name={"messageText"} title={"Text"}/>
                                        <Column name={"sentTo"} title={"Devices"}/>
                                        <Column name={"sentOn"} title={"Date"}/>
                                    </TableComponent>

                                    <div className={"ep-table-paginate-container"}>
                                        <div className={"push"}/>
                                        <TablePaginator
                                            page={this.state.currentPage}
                                            totalCount={this.state.tableTotal}
                                            pageSize={this.state.tableLimit}
                                            onClick={(page) => this.tablePageDidChange(page)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>

                <UIBlocker shown={this.state.messageNetworkInFlight} />
            </div>
        )
    }
}
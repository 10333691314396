import {BaseModal} from "../common/BaseModal";
import {useEffect, useState} from "react";
import LoadingSpinner from "../common/LoadingSpinner";
import Validator from "../../util/Validator";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import {API} from "../../network/API";

export const GuideCategoryEditorModal = (props) => {

    const {shown} = props;
    const {categoryId} = props;
    const {callback} = props;

    const [category, setCategory] = useState(null);
    const [categoryTitle, setCategoryTitle] = useState(null);
    const [categoryActive, setCategoryActive] = useState("1");

    const [error, setError] = useState(null);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [forceDismiss, setForceDismiss] = useState(false);
    const [callbackData, setCallbackData] = useState(false);

    useEffect(() => {
        if (shown) {
            if (categoryId) {
                fetchCategoryFromNetwork();
            }
        } else {
            setCategory(null);
            setCategoryTitle(null);
            setCategoryActive("1");
            setForceDismiss(false);
            setCallbackData(null);
            setError(null);
        }
    }, [shown])

    useEffect(() => {
        if (category) {
            setCategoryTitle(category.categoryName);
            setCategoryActive(category.active);
        }
    }, [category]);

    function handleCallback(action) {
        if (callback) {
            callback(action, callbackData);
        }
    }

    function fetchCategoryFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let data = {
            ids : [categoryId],
            activeOnly : 0
        };

        Axios.post(Endpoints.guide.getCategories, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setCategory(resp.data.data[0]);
                    } else {
                        setError("Could not find Category by given ID");
                    }
                } else {
                    setError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setError("An unknown errror has occurred. Please try again later. [CE1000C]");
                setNetworkInFlight(false);
            })
    }

    function submitForm() {
        if (networkInFlight) return;

        setError(null); // Clear error now

        const validationResult = Validator.validateCreateFormData({
            categoryId, categoryTitle, categoryActive
        }, [
            Validator.rule("categoryId", "int", "", "id", true),
            Validator.rule("categoryTitle", "string", "Category Name", "categoryName"),
            Validator.rule("categoryActive", "int", "Active", "active", true)
        ]);

        if (!validationResult.success) {
            setError(validationResult.error);
            return;
        }

        setNetworkInFlight(true);

        Axios.post(Endpoints.guide.submitCategory, validationResult.formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setCallbackData(resp.data.category);
                    setForceDismiss(true);
                } else {
                    setError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                setError("An unknown error has occurred. [GCS1000C]");
            })
    }

    if (!shown) return [];

    let submitAction = (
        <>
            <button className={"btn btn-light"} onClick={() => submitForm()}>Save</button>
            <button className={"btn btn-secondary ms-1"} onClick={() => setForceDismiss(true)}>Cancel</button>
        </>
    );

    if (networkInFlight) {
        submitAction = (<LoadingSpinner />);
    }

    let errorMessage = [];
    if (error !== null) {
        errorMessage = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <span className={"text-danger"}>{error}</span>
                </div>
            </div>
        )
    }

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <h3>Category</h3>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>Category Name</label>
                    <input type={"text"} className={"form-control"} value={categoryTitle} onChange={(e) => setCategoryTitle(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>Active</label>
                    <select className={"form-select"} value={categoryActive} onChange={(e) => setCategoryActive(e.target.value)}>
                        <option value={"0"}>No</option>
                        <option value={"1"}>Yes</option>
                    </select>
                </div>
            </div>

            {errorMessage}

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {submitAction}
                </div>
            </div>

        </BaseModal>
    )


}
import BaseComponent from "../BaseComponent";
import ScreenTitle from "../common/ScreenTitle";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import LoadingSpinner from "../common/LoadingSpinner";
import {AppUserRoles} from "../../network/AppUserRoles";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import {API} from "../../network/API";
import AlertModal from "../common/AlertModal";

export default class UsersComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            usersNetworkInFlight : false,
            tableData : [],
            currentPage : 1,
            tableLimit : 20,
            tableTotal : 0,
            tableKeyword : "",
            sendingNew : false
        });
    }

    componentDidMount() {
        this.fetchUsersFromNetwork();
    }

    moveToEditor = (id) => {
        if (id === undefined) {
            id = "new";
        }
        this.props.history.push("/users/" + id);
    }

    tableRowWasClicked = (row) => {
        this.moveToEditor(row.id);
    }

    tablePageDidChange = (page) => {
        this.setState({
            tableData : [],
            currentPage : page
        }, () => {
            this.fetchUsersFromNetwork();
        });
    }

    fetchUsersFromNetwork = () => {
        if (this.state.usersNetworkInFlight) return;
        this.setState({
            usersNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", this.state.currentPage);
        // formData.append("userRoleId", AppUserRoles.ADMINISTRATOR); // TODO
        formData.append("limit", this.state.tableLimit);

        Axios.post(Endpoints.user.getUsers, formData)
            .then((r) => {
                let tableData = [];
                let tableTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    tableData = resp.data.data;
                    tableTotal = resp.data.count;
                } else {
                    AlertModal.showModal("Error", API.formatError(resp));
                }

                this.setState({
                    usersNetworkInFlight : false,
                    tableData,
                    tableTotal
                });
            })
            .catch((e) => {
                this.setState({
                    usersNetworkInFlight : false
                });

                AlertModal.showModal("Error", "An unknown error has occurred. Please reload the page. [DU1000]");
            });
    }

    dlCSV() {

        const FileDownload = require('js-file-download');
        const formData = new FormData();

        Axios
            .post(Endpoints.user.getCSV, formData)
            .then((response) => {
                FileDownload(response.data, 'AppUsersNICEIC.csv');
            }).catch(error => {

            var msg = "Error fetching file. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)
            }
        });

    }


    render() {
        let emptyMessage = undefined;
        if (this.state.usersNetworkInFlight) {
            emptyMessage = (<LoadingSpinner shown={true} />);
        }

        return (
            <div className={"vendors-landing-component"}>
                <div className={"container"}>
                        <div className={"row screen-title-container"}>
                            <div className={"col-12 col-md-6"}>
                                <ScreenTitle title={"Users"} showBackButton={false} {...this.props} />
                            </div>
                            <div className={"col-12 col-md-6 screen-actions animated"}>
                                <button className={"btn btn-light"} onClick={() => this.moveToEditor()}>Add User
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button className={"btn btn-light"} onClick={() => this.dlCSV()}>Download Users</button>
                            </div>
                        </div>


                        <div className={"app-content animate-screen-did-appear"}>
                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <TableComponent
                                    className={"alternating clickable small-table"}
                                    data={this.state.tableData}
                                    onRowClick={this.tableRowWasClicked}
                                    emptyMessage={emptyMessage}>
                                    <Column name={"givenName"} title={"Given Name"} />
                                    <Column name={"familyName"} title={"Family Name"} />
                                    <Column name={"emailAddress"} title={"Email"} />
                                    <Column name={"active"} title={"Active"} className={"text-center"} render={(data, row) => {
                                        let label = "No";
                                        if (parseInt(data) === 1) {
                                            label = "Yes";
                                        }
                                        return label;
                                    }} />
                                </TableComponent>

                                <div className={"ep-table-paginate-container"}>
                                    <div className={"push"} />
                                    <TablePaginator
                                        page={this.state.currentPage}
                                        totalCount={this.state.tableTotal}
                                        pageSize={this.state.tableLimit}
                                        onClick={(page) => this.tablePageDidChange(page)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
import BaseComponent from "../BaseComponent";
import {AppUserRoles} from "../../network/AppUserRoles";
import Axios from "axios";
import {API} from "../../network/API";
import {Endpoints} from "../../network/Endpoints";
import {DataManager} from "../../data/DataManager";

import "./LoginComponent.css";

import logoImage from "../../assets/certsure/image/niceic_logo.svg";
import UIBlocker from "../common/UIBlocker";
import AlertModal from "../common/AlertModal";

export default class LoginComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            loginNetworkInFlight : false
        });
    }

    submitLogin = (e) => {
        if (e !== undefined) { e.preventDefault(); e.stopPropagation(); }
        if (this.state.loginNetworkInFlight) return;

        let validationResult = this.validateCreateFormData(this.state, [
            { key : "username", type : "string", label : "Username", postName : "emailAddress"},
            { key : "password", type : "string", label : "Password", postName : "password"}
        ]);

        if (validationResult.success) {
            this.setState({
                loginNetworkInFlight : true
            });

            let formData = validationResult.formData;
            formData.append("matchUserRoleIds", JSON.stringify([AppUserRoles.ADMINISTRATOR]));

            Axios.post(Endpoints.auth.login, formData)
                .then((r) => {
                    let resp = API.parse(r);
                    if (resp.success) {
                        if (resp.data.sessionToken !== undefined) {
                            DataManager.setAuthenticationToken(resp.data.sessionToken);
                            DataManager.setUser(resp.data.user);
                        } else {
                            AlertModal.showModal("Error", API.formatError(resp));
                        }
                    } else {
                        AlertModal.showModal("Error", API.formatError(resp));
                    }

                    this.setState({
                        loginNetworkInFlight : false
                    });
                })
                .catch((e) => {
                    console.log(e);

                    AlertModal.showModal("Error", "An unknown error has occurred. Please try again later.");
                    this.setState({
                        loginNetworkInFlight : false
                    });
                });
        } else {
            console.log(validationResult.error);
            AlertModal.showModal("Error", validationResult.error);
            this.setState({
                loginNetworkInFlight : false
            });
        }
    }

    render() {
        return (
            <div className={"login-component"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-1 col-sm-2 col-md-3 col-lg-4"} />
                        <div className={"col-10 col-sm-8 col-md-6 col-lg-4"}>
                            <div className={"login-box animate-screen-did-appear"}>

                                <div className={"ratio ratio-21x9 logo"}>
                                    <div className={"image"} style={{backgroundImage : "url(" + logoImage + ")"}} />
                                    <div className={"wiper"} />
                                </div>

                                <form action={"#"} onSubmit={this.submitLogin}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>Username</label>
                                            <input type={"text"} className={"form-control"} name={"username"} value={this.state.username} onChange={this.handleChange} />
                                        </div>
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <div className={"col-12"}>
                                            <label>Password</label>
                                            <input type={"password"} className={"form-control"} name={"password"} value={this.state.password} onChange={this.handleChange} />
                                        </div>
                                    </div>

                                    <div className={"col-12 mt-4"}>
                                        <div className={"col-12"}>
                                            <input type={"submit"} className={"btn btn-light full-width-button"} name={"form-submit"} value={"Submit"} />
                                        </div>
                                    </div>
                                </form>

                                <div className={"ratio ratio-21x9"} />

                            </div>
                        </div>
                    </div>
                </div>

                <UIBlocker shown={this.state.loginNetworkInFlight} />
            </div>
        )
    }

}
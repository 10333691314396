import BaseComponent from "../BaseComponent";

import "./ScreenTitle.css";
import backArrow from "../../assets/certsure/image/ui_back.svg";

export default class ScreenTitle extends BaseComponent {

    goBack = () => {
        if (this.props.history !== undefined) {
            if (this.props.backLocation !== undefined) {
                this.props.history.push(this.props.backLocation);
            } else {
                this.props.history.goBack();
            }
        }
    }

    render() {
        let title = "";
        if (this.props.title !== undefined) {
            title = this.props.title;
        }

        let showBackButton = true;
        if (this.props.showBackButton !== undefined) {
            showBackButton = this.props.showBackButton;
        }

        let animated = true;
        if (this.props.animate) {
            animated = this.props.animate;
        }

        let backButton = [];
        if (showBackButton) {
            backButton = (
                <div className={"back-button"} style={{backgroundImage : "url(" + backArrow + ")"}} onClick={this.goBack} />
            );
        }

        let coreExtraClass = "";
        if (animated) {
            coreExtraClass += " animated";
        }

        return (
            <div className={"screen-title" + coreExtraClass}>
                {backButton}
                <div className={"text-container"}>
                    <div className={"text-area"}>
                        <div className={"text-container"}>
                            <span className={"title-text"}>{title}</span>
                            <span className={"wiper"} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
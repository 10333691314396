import BaseComponent from "../BaseComponent";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import {API} from "../../network/API";
import AlertModal from "../common/AlertModal";
import ScreenTitle from "../common/ScreenTitle";
import UIBlocker from "../common/UIBlocker";
import LoadingSpinner from "../common/LoadingSpinner";
import Validator from "../../util/Validator";
import {v4 as uuidv4} from 'uuid'
import ArrayUtil from "../../util/ArrayUtil";

import upIcon from "../../assets/certsure/image/ic_arrow_up_dark.svg";
import downIcon from "../../assets/certsure/image/ic_arrow_down_dark.svg";
import deleteIcon from "../../assets/certsure/image/ic_delete.svg";

export default class GuideEditorComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let guideId = this.getUriProperty("id", "new");
        console.log("GuideId: " + guideId);

        this.initState({
            guideId,
            guide : null,
            guideIsAdiabaticCalculatorGuide : 0,
            guideIsZsValuesCalculatorGuide : 0,
            guideActive : 1,
            guideNetworkInFlight : false,
            guideSubmissionNetworkInFlight : false,
            selectedFile: null,
            isFilePicked: false,
            categoryNetworkInFlight : false,
            categories : [],
            selectedCategoryIds : [],
            deletedMoreInfoIds : []
        });
    }

    componentDidMount() {
        console.log("GuideId: " + this.state.guideId);
        if (this.state.guideId !== null && this.state.guideId !== "new") {
            this.fetchGuideFromNetwork();
        }
        this.fetchCategoriesFromNetwork();
    }

    populateGuideIntoState = (guide) => {
        // Assign a local ID to each inbound More Info so we can keep track of it
        if (guide.moreInfo !== undefined) {
            for (let i = 0; i < guide.moreInfo.length; i++) {
                guide.moreInfo[i].localId = uuidv4()
            }
        }

        this.populateObjectIntoState(guide, "guide");

        let categoryIds = [];
        if (guide.hasOwnProperty("categories")) {
            guide.categories.forEach((record) => {
                categoryIds.push(record.categoryId);
            });
        }

        this.setState({
            guide,
            selectedCategoryIds : categoryIds
        });
    }

    populateGuideIdIntoState = (guideId) => {
        this.populateObjectIntoState(guideId, "guideId");

        this.setState({
            guideId
        });
    }

    createMoreInfoElement = (moreInfo) => {
        let description = "";
        let url = "";

        if (moreInfo.description !== undefined) {
            description = moreInfo.description;
        }

        if (moreInfo.url !== undefined) {
            url = moreInfo.url;
        }

        return (
            <div className={"card"}>
                <div className={"card-body"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <label>Description</label>
                            <input type={"text"} className={"form-control"} value={description} onChange={(e) => this.setMoreInfoProperty(moreInfo.localId, "description", e.target.value)} />
                        </div>
                    </div>

                    <div className={"row mt-2"}>
                        <div className={"col-12"}>
                            <label>Url</label>
                            <input type={"text"} className={"form-control"} value={url} placeholder={"https://"} onChange={(e) => this.setMoreInfoProperty(moreInfo.localId, "url", e.target.value)} />
                        </div>
                    </div>

                    <div className={"row mt-2"}>
                        <div className={"col-6"}>
                            <button className={"btn btn-outline-secondary button-icon me-1"} onClick={() => this.moveMoreInfoItem(moreInfo.localId, "up")} style={{backgroundImage : "url(" + upIcon + ")"}} />
                            <button className={"btn btn-outline-secondary button-icon"} onClick={() => this.moveMoreInfoItem(moreInfo.localId, "down")} style={{backgroundImage : "url(" + downIcon + ")"}} />
                        </div>
                        <div className={"col-6 text-end"}>
                            <button className={"btn btn-outline-danger button-icon"} onClick={() => this.removeMoreInfo(moreInfo.localId)}  style={{backgroundImage : "url(" + deleteIcon + ")"}} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    setMoreInfoProperty = (localId, propertyName, value) => {
        let moreInfo = [...this.state.guideMoreInfo];
        for (let i = 0; i < moreInfo.length; i++) {
            if (moreInfo[i].localId === localId) {
                moreInfo[i][propertyName] = value;
                break;
            }
        }
        this.setState({
            guideMoreInfo : moreInfo
        });
    }

    addMoreInfoItem = () => {
        let moreInfo = [...this.state.guideMoreInfo];
        if (moreInfo.length < 3) {
            moreInfo.push({
                localId : uuidv4(),
                description : "",
                url : ""
            });
        }

        this.setState({
            guideMoreInfo : moreInfo
        });
    }

    moveMoreInfoItem = (localId, direction) => {
        let moreInfo = [...this.state.guideMoreInfo];

        let index = -1;
        for (let i = 0; i < moreInfo.length; i++) {
            if (moreInfo[i].localId === localId) {
                index = i;
                break;
            }
        }

        if (index >= 0) {
            let nextIndex = index;
            if (direction === "up") {
                nextIndex--;
            } else {
                nextIndex++;
            }

            if (nextIndex < 0) {
                return;
            } else if (nextIndex >= moreInfo.length) {
                return;
            }

            ArrayUtil.arrayMoveMutable(moreInfo, index, nextIndex);

            this.setState({
                guideMoreInfo : moreInfo
            });
        }
    }

    removeMoreInfo = (localId) => {
        let moreInfo = [...this.state.guideMoreInfo];
        let deletedMoreInfoIds = [...this.state.deletedMoreInfoIds];

        for (let i = 0; i < moreInfo.length; i++) {
            if (moreInfo[i].localId === localId) {
                if (moreInfo[i].id !== undefined) {
                    console.log("HAS ID: " + moreInfo[i].id);
                    // If this came from the server, note the ID so we can notify the API of its removal
                    deletedMoreInfoIds.push(moreInfo[i].id);
                }

                moreInfo.splice(i, 1);
                break;
            }
        }

        this.setState({
            guideMoreInfo : moreInfo,
            deletedMoreInfoIds
        });
    }

    fetchGuideFromNetwork = () => {
        if (this.state.guideNetworkInFlight) return;
        this.setState({
            guideNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("guideId", this.state.guideId);
        formData.append("activeOnly", 0);
        formData.append("fetchCategories", 1);
        formData.append("fetchMoreInfo", 1);

        Axios.post(Endpoints.guide.getGuide, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateGuideIntoState(resp.data.guide);
                } else {
                    AlertModal.showModal("Error", API.formatError(resp));
                }

                this.setState({
                    guideNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                // AlertModal.showModal("Error", "An unknown error has occurred. Please reload the page. [DU20000]");
                AlertModal.showModal("Error", e.message);
            });
    }

    fetchCategoriesFromNetwork = () => {
        if (this.state.categoryNetworkInFlight) return;
        this.setState({ categoryNetworkInFlight : true });

        Axios.get(Endpoints.guide.getCategories)
            .then((r) => {
                let categories = this.state.categories;

                const resp = API.parse(r);
                if (resp.success) {
                    categories = resp.data.data;
                } else {
                    console.log(API.formatError(resp));
                }
                this.setState({
                    categoryNetworkInFlight : false,
                    categories
                });
            })
            .catch((e) => {
                console.log(e);
                this.setState({ categoryNetworkInFlight : false });
            });
    }

    submitGuideOverNetwork = () => {
        if (this.state.guideSubmissionNetworkInFlight) return;
        this.setState({
            guideSubmissionNetworkInFlight : true
        });

        let validationResult = this.validateCreateFormData(this.state, [
            { key : "guideTitle", type : "string", label : "Title", postName : "title"},
            { key : "guideDescription", type : "string", label : "Description", postName : "description"},
            { key : "guideActive", type : "int", label : "Active", postName : "active"},
            { key : "guideIsAdiabaticCalculatorGuide", type : "int", label : "Adiabatic Calculator Guide", postName : "isAdiabaticCalculatorGuide"},
            { key : "guideIsZsValuesCalculatorGuide", type : "int", label : "Zs Values Calculator Guide", postName : "isZsValuesCalculatorGuide"}
        ]);

        if (!validationResult.success) {
            console.log(validationResult.error);
            AlertModal.showModal("Error", validationResult.error);
            this.setState({
                guideSubmissionNetworkInFlight : false,
            });
            return;
        }

        let formData = validationResult.formData;
        if (this.state.guideId !== null && this.state.guideId !== "new") {
            formData.append("id", this.state.guideId);
        } else if(this.state.isFilePicked){
            formData.append("guideFile", this.state.selectedFile);
        } else {
            AlertModal.showModal("Error", "Please select a file to upload");
            this.setState({
                guideSubmissionNetworkInFlight : false,
            });
            return;
        }

        formData.append("categoryIds", JSON.stringify(this.state.selectedCategoryIds));

        if (this.state.guideMoreInfo !== undefined) {
            formData.append("moreInfo", JSON.stringify(this.state.guideMoreInfo));
            formData.append("deletedMoreInfoIds", JSON.stringify(this.state.deletedMoreInfoIds));
        }

        Axios.post(Endpoints.guide.submitGuide, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateGuideIntoState(resp.data.guide);
                    AlertModal.showModal("Success", "Guide has been successfully uploaded.");
                } else {
                    AlertModal.showModal("Error", "API Error: " + API.formatError(resp));
                }

                this.setState({
                    guideSubmissionNetworkInFlight : false,
                });
            })
            .catch((e) => {
                console.log(e);
                // AlertModal.showModal("Error", "An unknown error has occurred. Please reload the page. [DU20000]");
                AlertModal.showModal("Error", e.message);

                this.setState({
                    guideSubmissionNetworkInFlight : false
                });
            });
    }

    fileChangeHandler = (event) => {
        this.setState({
            selectedFile : event.target.files[0],
            isFilePicked : true
        });
    }

    checkCategory = (id, checked) => {
        let ids = [...this.state.selectedCategoryIds];

        let index = -1;

        for (let i = 0; i < ids.length; i++) {
            if (parseInt(ids[i]) === parseInt(id)) {
                index = i;
                break;
            }
        }

        if (index !== -1) {
            ids.splice(index, 1);
        } else {
            ids.push(parseInt(id));
        }

        this.setState({
            selectedCategoryIds : ids
        });
    }

    render() {
        let primaryContent = (
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner shown={true} />
                </div>
            </div>
        );
        if (!this.state.guideNetworkInFlight) {
            const categoryElems = []

            this.state.categories.forEach((category) => {
                let selected = false;
                for (let i = 0; i < this.state.selectedCategoryIds.length; i++) {
                    if (parseInt(category.id) === parseInt(this.state.selectedCategoryIds[i])) {
                        selected = true;
                        break;
                    }
                }

                categoryElems.push(
                    <li className="list-group-item">
                        <label className="form-check-label">
                            <input className="form-check-input me-1" type="checkbox" value={category.id} checked={selected} onChange={(e) => this.checkCategory(category.id, e.target.checked)} />
                            {category.categoryName}
                        </label>
                    </li>
                )
            });

            let moreInfoElems = [];
            if (this.state.guideMoreInfo !== undefined) {
                for (let i = 0; i < this.state.guideMoreInfo.length; i++) {
                    moreInfoElems.push(
                        <div className={"row mb-2"}>
                            <div className={"col-12"}>
                                {this.createMoreInfoElement(this.state.guideMoreInfo[i])}
                            </div>
                        </div>
                    )
                }

                if (this.state.guideMoreInfo.length < 3) {
                    moreInfoElems.push(
                        <div className={"row mt-2"}>
                            <div className={"col-12 text-center"}>
                                <button className={"btn btn-light"} onClick={() => this.addMoreInfoItem()}>Add More Info</button>
                            </div>
                        </div>
                    );
                }
            }

            primaryContent = (
                <div className={"app-content animate-screen-did-appear"}>
                    <div className={"row mt-2"}>
                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Title</label>
                            <input type={"text"} className={"form-control"} name={"guideTitle"} value={this.state.guideTitle} onChange={this.handleChange} />
                        </div>

                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Description</label>
                            <input type={"text"} className={"form-control"} name={"guideDescription"} value={this.state.guideDescription} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className={"row mt-2"}>
                        {this.state.guide ? (
                                <div className={"col-12 col-md-6 mt-2"}>
                                </div>
                        ) : (
                            <div className={"col-12 col-md-6 mt-2"}>
                                <input type={"file"} name={"guideFile"} onChange={this.fileChangeHandler} />
                                {this.state.isFilePicked ? (
                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <p>Filename: {this.state.selectedFile.name}</p>
                                        <p>Filetype: {this.state.selectedFile.type}</p>
                                        <p>Size in bytes: {this.state.selectedFile.size}</p>
                                        <p>
                                            lastModifiedDate:{' '}
                                            {this.state.selectedFile.lastModifiedDate.toLocaleDateString()}
                                        </p>
                                    </div>
                                ) : (
                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <p>Select a file to show details</p>
                                    </div>
                                )}
                            </div>
                        )}

                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Active</label>
                            <select className={"form-select"} name={"guideActive"} value={this.state.guideActive} onChange={this.handleChange}>
                                <option value={"1"}>Yes</option>
                                <option value={"0"}>No</option>
                            </select>
                        </div>
                    </div>

                    <div className={"row mt-2"}>

                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Is Adiabatic Calculator Guide</label>
                            <select className={"form-select"} name={"guideIsAdiabaticCalculatorGuide"} value={this.state.guideIsAdiabaticCalculatorGuide} onChange={this.handleChange}>
                                <option value={"1"}>Yes</option>
                                <option value={"0"}>No</option>
                            </select>
                        </div>

                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Is Zs Values Calculator Guide</label>
                            <select className={"form-select"} name={"guideIsZsValuesCalculatorGuide"} value={this.state.guideIsZsValuesCalculatorGuide} onChange={this.handleChange}>
                                <option value={"1"}>Yes</option>
                                <option value={"0"}>No</option>
                            </select>
                        </div>
                    </div>

                    <div className={"row justify-content-center"}>
                        <div className={"col-12 col-md-6 mt-4"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <h3>Category Association</h3>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <ul className="list-group">
                                        {categoryElems}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={"col-12 col-md-6 mt-4"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <h3>More Info</h3>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    {moreInfoElems}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row mt-4"}>
                        <div className={"col-12 text-center"}>
                            <button className={"btn btn-success"} onClick={this.submitGuideOverNetwork}>Save Changes</button>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className={"vendor-editor-component"}>
                <div className={"container"}>
                    <div className={"row screen-title-container"}>
                        <div className={"col-12"}>
                            <ScreenTitle title={"Guide"} {...this.props} />
                        </div>
                    </div>

                    {primaryContent}

                    <UIBlocker shown={this.state.guideSubmissionNetworkInFlight} />
                </div>
            </div>
        )
    }
}
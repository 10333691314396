import ScreenTitle from "../common/ScreenTitle";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import {useEffect, useState} from "react";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import {API} from "../../network/API";
import AlertModal from "../common/AlertModal";
import {NavLink} from "react-router-dom";

export const LinkItemListComponent = (props) => {

    const {id} = props.match.params;

    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [page, setPage] = useState(1);

    useEffect(() => {
        fetchLinksFromNetwork();
    }, []);

    function moveToEditor(typeId, id) {
        if (!typeId) {
            typeId = 1;
        }

        if (id === undefined) {
            id = "new";
        }

        if (props.history) {
            props.history.push("/link/" + typeId + "/" + id);
        }
    }

    function tableRowWasClicked(row) {
        moveToEditor(id, row.id);
    }

    function fetchLinksFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let data = {
            typeId : id ? id : 1, // If no ID is provided assume ID 1
            page
        };

        Axios.post(Endpoints.more.getMoreLinkItems, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        let source = resp.data.data[0].moreItemLinks;
                        setTableData(source.data);
                        setTotalCount(source.count);
                    } else {
                        console.log("No type found.");
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError("An unknown error has occurred. Please try again later. [MLI1000C]");
            });
    }

    let emptyMessage = "Nothing to display";
    if (networkInFlight) {
        emptyMessage = "Loading...";
    }

    return (
        <div className={"news-list-component"}>
            <div className={"container"}>
                <div className={"row screen-title-container"}>
                    <div className={"col-12 col-md-6"}>
                        <ScreenTitle title={"Links"} showBackButton={true} {...props} />
                    </div>
                    <div className={"col-12 col-md-6 screen-actions animated"}>
                        <NavLink to={"/links-sort/"} className={"btn btn-light me-1"}>Sort Links</NavLink>
                        <button className={"btn btn-light"} onClick={() => moveToEditor()}>New Link</button>
                    </div>
                </div>

                <div className={"app-content animate-screen-did-appear"}>

                    <div className={"row mt-2"}>
                        <div className={"col-12"}>

                            <TableComponent
                                className={"alternating clickable small-table"}
                                data={tableData}
                                onRowClick={tableRowWasClicked}
                                emptyMessage={emptyMessage}>

                                <Column name={"title"} title={"Title"} />
                                <Column name={"url"} className={"text-center"} title={"URL"} />
                                <Column name={"id"} className={"text-end"} render={(data, row) => (
                                    <span className={"btn btn-light"}>View</span>
                                )} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={totalCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
import BaseComponent from "../BaseComponent";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import {API} from "../../network/API";
import AlertModal from "../common/AlertModal";
import ScreenTitle from "../common/ScreenTitle";
import UIBlocker from "../common/UIBlocker";
import LoadingSpinner from "../common/LoadingSpinner";
import {AppUserRoles} from "../../network/AppUserRoles";

export default class UserEditorComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let userId = this.getUriProperty("id", "new");

        this.initState({
            userId,
            user : null,
            userNetworkInFlight : false,
            userSubmissionNetworkInFlight : false,
            userUserRoleId : AppUserRoles.ADMINISTRATOR,
            userActive : 1,
            userIsCMSUser : 1
        });
    }

    componentDidMount() {
        if (this.state.userId !== null && this.state.userId !== "new") {
            this.fetchUserFromNetwork();
        }
    }

    populateUserIntoState = (user) => {
        this.populateObjectIntoState(user, "user");

        if(this.state.userUserRoleId === AppUserRoles.ADMINISTRATOR) {
            this.setState({
                user,
                userIsCMSUser : 1
            });
        } else if(this.state.userUserRoleId === AppUserRoles.USER){
            this.setState({
                user,
                userIsCMSUser : 0
            });
        }

    }

    fetchUserFromNetwork = () => {
        if (this.state.userNetworkInFlight) return;
        this.setState({
            userNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("userId", this.state.userId);

        Axios.post(Endpoints.user.getUser, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateUserIntoState(resp.data.user);
                } else {
                    AlertModal.showModal("Error", API.formatError(resp));
                }

                this.setState({
                    userNetworkInFlight : false
                });
            })
            .catch((e) => {
                AlertModal.showModal("Error", "An unknown error has occurred. Please reload the page. [DU20000]");
                this.setState({
                    userNetworkInFlight : false
                });
            });
    }

    submitUserOverNetwork = () => {
        if (this.state.userSubmissionNetworkInFlight) return;
        this.setState({
            userSubmissionNetworkInFlight : true
        });

        let validationResult = this.validateCreateFormData(this.state, [
            { key : "userGivenName", type : "string", label : "Given Name", postName : "givenName"},
            { key : "userFamilyName", type : "string", label : "Family Name", postName : "familyName"},
            { key : "userEmailAddress", type : "string", label : "Email Address", postName : "emailAddress"},
            { key : "userUserRoleId", type : "int", label : "User Role", postName : "userRoleId"},
            { key : "userActive", type : "int", label : "Active", postName : "active"},
            { key : "userCompanyName", type : "string", label : "Company Name", postName : "companyName"},
            { key : "userRegistrationNumber", type : "string", label : "Registration Number", postName : "registrationNumber"},
            { key : "userPassword", type : "string", label : "Password", postName : "password", optional : true},
        ]);

        if (!validationResult.success) {
            console.log(validationResult.error);
            AlertModal.showModal("Error", validationResult.error);
            this.setState({
                userSubmissionNetworkInFlight : false
            });
            return;
        }

        let formData = validationResult.formData;
        if (this.state.userId !== null && this.state.userId !== "new") {
            formData.append("id", this.state.userId);
        }

        if(this.state.userIsCMSUser) {
            Axios.post(Endpoints.user.submitCMSUser, formData)
                .then((r) => {
                    let resp = API.parse(r);
                    if (resp.success) {
                        this.populateUserIntoState(resp.data.user);
                        AlertModal.showModal("Success", "User has been successfully updated.");
                    } else {
                        AlertModal.showModal("Error", API.formatError(resp));
                    }

                    this.setState({
                        userSubmissionNetworkInFlight : false,
                        userPassword : ""
                    });
                })
                .catch((e) => {
                    console.log(e);
                    AlertModal.showModal("Error", "An unknown error has occurred. Please reload the page. [DU30000]");

                    this.setState({
                        userSubmissionNetworkInFlight : false
                    });
                });
        } else {
            Axios.post(Endpoints.user.submitUser, formData)
                .then((r) => {
                    let resp = API.parse(r);
                    if (resp.success) {
                        this.populateUserIntoState(resp.data.user);
                        AlertModal.showModal("Success", "User has been successfully updated.");
                    } else {
                        AlertModal.showModal("Error", API.formatError(resp));
                    }

                    this.setState({
                        userSubmissionNetworkInFlight : false,
                        userPassword : ""
                    });
                })
                .catch((e) => {
                    console.log(e);
                    AlertModal.showModal("Error", "An unknown error has occurred. Please reload the page. [DU30000]");

                    this.setState({
                        userSubmissionNetworkInFlight : false
                    });
                });
        }

    }

    promptToDelete = () =>  {
        if (window.confirm("Delete this user, are you sure?")) {
            var formData = new FormData();
            formData.append("userId", this.state.userId);
            Axios.post(Endpoints.user.deleteUser, formData)
                .then((r) => {
                    let resp = API.parse(r);
                    if (resp.success) {
                        if (this.props.history !== undefined) {
                            if (this.props.backLocation !== undefined) {
                                this.props.history.push(this.props.backLocation);
                            } else {
                                this.props.history.goBack();
                            }
                        }
                    } else {
                        AlertModal.showModal("Error", API.formatError(resp));
                    }

                    this.setState({
                        userSubmissionNetworkInFlight : false,
                        userPassword : ""
                    });
                })
                .catch((e) => {
                    console.log(e);
                    AlertModal.showModal("Error", "An unknown error has occurred. Please reload the page. [DU40000]");

                    this.setState({
                        userSubmissionNetworkInFlight : false
                    });
                });
        }
    }

    render() {
        let primaryContent = (
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner shown={true} />
                </div>
            </div>
        );
        if (!this.state.userNetworkInFlight) {
            primaryContent = (
                <div className={"app-content animate-screen-did-appear"}>
                    <div className={"row mt-2"}>
                        <div>
                            <button className={"btn btn-danger"} style={{float:"right"}} onClick={this.promptToDelete}>Delete User</button>
                        </div>
                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Given Name</label>
                            <input type={"text"} className={"form-control"} name={"userGivenName"} value={this.state.userGivenName} onChange={this.handleChange} />
                        </div>

                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Family Name</label>
                            <input type={"text"} className={"form-control"} name={"userFamilyName"} value={this.state.userFamilyName} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className={"row mt-2"}>
                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Company Name</label>
                            <input type={"text"} className={"form-control"} name={"userCompanyName"} value={this.state.userCompanyName} onChange={this.handleChange} />
                        </div>

                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Registration Number</label>
                            <input type={"text"} className={"form-control"} name={"userRegistrationNumber"} value={this.state.userRegistrationNumber} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className={"row mt-2"}>
                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Email Address</label>
                            <input type={"text"} className={"form-control"} name={"userEmailAddress"} value={this.state.userEmailAddress} onChange={this.handleChange} />
                        </div>

                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>User Role ID</label>
                            <select className={"form-select"} name={"userUserRoleId"} value={this.state.userUserRoleId} onChange={this.handleChange}>
                                <option value={"1"}>Administrator</option>
                                <option value={"2"}>Regular User</option>
                            </select>
                        </div>
                    </div>

                    <div className={"row mt-2"}>
                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Password (Leave blank to remain unchanged)</label>
                            <input type={"password"} className={"form-control"} name={"userPassword"} value={this.state.userPassword} onChange={this.handleChange} />
                        </div>

                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Active</label>
                            <select className={"form-select"} name={"userActive"} value={this.state.userActive} onChange={this.handleChange}>
                                <option value={"1"}>Yes</option>
                                <option value={"0"}>No</option>
                            </select>
                        </div>
                    </div>

                    <div className={"row mt-2"}>
                        <div className={"col-12 col-md-6 mt-2"}>
                            <label>Is CMS User</label>
                            <select className={"form-select"} name={"userIsCMSUser"} value={this.state.userIsCMSUser} onChange={this.handleChange}>
                                <option value={"1"}>Yes</option>
                                <option value={"0"}>No</option>
                            </select>
                        </div>
                    </div>

                    <div className={"row mt-2"}>
                        <div className={"col-12 text-center"}>
                            <button className={"btn btn-success"} onClick={this.submitUserOverNetwork}>Save Changes</button>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className={"vendor-editor-component"}>
                <div className={"container"}>
                    <div className={"row screen-title-container"}>
                        <div className={"col-12"}>
                            <ScreenTitle title={"User"} {...this.props} />
                        </div>
                    </div>

                    {primaryContent}

                    <UIBlocker shown={this.state.userSubmissionNetworkInFlight} />
                </div>
            </div>
        )
    }
}
import ScreenTitle from "../common/ScreenTitle";
import {useEffect, useState} from "react";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import AlertModal from "../common/AlertModal";
import {API} from "../../network/API";
import LoadingSpinner from "../common/LoadingSpinner";
import ArrayUtil from "../../util/ArrayUtil";

import "./LinkSortComponent.css";

export const LinkSortComponent = (props) => {

    const {typeId} = props.match.params;

    const [links, setLinks] = useState([]);
    const [linksNetworkInFlight, setLinksNetworkInFlight] = useState(false);

    const [dragTarget, setDragTarget] = useState(null);
    const [dropTarget, setDropTarget] = useState(null);

    useEffect(() => {
        fetchLinksFromNetwork();
    }, []);

    function commitDrop() {
        if (dragTarget === null || dropTarget === null) {
            // Clear targets, await valid drop
            setDragTarget(null);
            setDropTarget(null);
            return;
        }

        let dragIndex = -1;
        let dropIndex = -1;

        for (let i = 0; i < links.length; i++) {
            if (links[i].id === dragTarget) {
                dragIndex = i;
            } else if (links[i].id === dropTarget) {
                dropIndex = i;
            }

            if (dragIndex !== -1 && dropIndex !== -1) {
                break;
            }
        }

        if (dragIndex !== -1 && dropIndex !== -1) {
            let newLinks = ArrayUtil.arrayMoveImmutable(
                links,
                dragIndex,
                dropIndex
            );

            setLinks(newLinks);
        }

        // Clear these now
        setDragTarget(null);
        setDropTarget(null);
    }

    function fetchLinksFromNetwork() {
        if (linksNetworkInFlight) return;
        setLinksNetworkInFlight(true);

        let data = {
            typeId : typeId ? typeId : 1 // Default to 1
        };

        Axios.post(Endpoints.more.getMoreLinkItems, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setLinks(resp.data.data[0].moreItemLinks);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setLinksNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setLinksNetworkInFlight(false);
                AlertModal.showError("Could not fetch Guides. Please try again later. [GS1000C]");
            })
    }

    function submitLinkOrderingOverNetwork() {
        if (linksNetworkInFlight) return;
        setLinksNetworkInFlight(true);

        let ids = [];
        for (let i = 0; i < links.length; i++) {
            ids.push(links[i].id);
        }

        let data = {
            typeId : typeId ? typeId : 1, // Default to 1
            ids : JSON.stringify(ids)
        };

        Axios.post(Endpoints.more.submitMoreLinkItemOrdering, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    AlertModal.showModal(
                        "Success",
                        "Guide ordering has been saved"
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setLinksNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setLinksNetworkInFlight(false);
                AlertModal.showError("An unknown error has occurred. Please try again later. [LS2000C]");
            })
    }

    let primaryContent = [];
    if (linksNetworkInFlight) {
        primaryContent = (
            <div className={"text-center"}>
                <LoadingSpinner inline={true} />
            </div>
        );
    } else {
        if (links) {
            primaryContent = links.map((link) => {
                let extraClass = "";
                if (link.id === dropTarget) {
                    extraClass = "drop-target";
                }

                return (
                    <div className={"link-item " + extraClass}
                         draggable={true}
                         onDrop={() => commitDrop()}
                         onDragStart={() => setDragTarget(link.id)}
                         onDragEnd={() => commitDrop()}
                         onDragEnter={() => setDropTarget(link.id)}
                         onDragExit={() => setDropTarget(null)}>

                        <div className={"card mb-2"}>
                            <div className={"card-body"}>
                                {link.title}
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    return (
        <div className={"link-sort-component"}>
            <div className={"container"}>
                <div className={"row screen-title-container"}>
                    <div className={"col-12 col-md-6"}>
                        <ScreenTitle title={"Sort Links"} showBackButton={true} {...props} />
                    </div>
                    <div className={"col-12 col-md-6 screen-actions animated"}>
                        <button className={"btn btn-light"} onClick={() => submitLinkOrderingOverNetwork()}>Save Ordering</button>
                    </div>
                </div>

                <div className={"app-content animate-screen-did-appear"}>
                    <div className={"row justify-content-center"}>
                        <div className={"col-12 col-sm-8 col-md-6 col-lg-4"}>
                            {primaryContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
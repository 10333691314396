import BaseComponent from "../BaseComponent";
import ScreenTitle from "../common/ScreenTitle";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import LoadingSpinner from "../common/LoadingSpinner";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import {API} from "../../network/API";
import AlertModal from "../common/AlertModal";
import {NavLink} from "react-router-dom";

export default class GuidesComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            guidesNetworkInFlight : false,
            tableData : [],
            currentPage : 1,
            tableLimit : 20,
            tableTotal : 0,
            tableKeyword : "",
            activeOnly : true,
            allGuides : false

        });
        this.handleChange = this.handleChange.bind(this);

    }


    handleChange(event) {

        const target = event.target;
        const name = target.name;
        const checked = target.checked;

        if (name === "activeOnly") {
            this.setState({
                [name]: checked,
                    allGuides: !checked,
                currentPage : 1
            }, ()=>this.fetchGuidesFromNetwork());
        } else {
            this.setState({
                [name]: checked,
                activeOnly: !checked,
                currentPage : 1
            }, ()=>this.fetchGuidesFromNetwork());
        }
    }

    componentDidMount() {
        this.fetchGuidesFromNetwork();
    }

    moveToEditor = (id) => {
        if (id === undefined) {
            id = "new";
        }
        this.props.history.push("/guide/" + id);
    }

    tableRowWasClicked = (row) => {
        this.moveToEditor(row.id);
    }

    tablePageDidChange = (page) => {
        this.setState({
            tableData : [],
            currentPage : page
        }, () => {
            this.fetchGuidesFromNetwork();
        });
    }

    fetchGuidesFromNetwork = () => {
        if (this.state.guidesNetworkInFlight) return;
        this.setState({
            guidesNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", this.state.currentPage);
        formData.append("limit", this.state.tableLimit);
        formData.append("activeOnly", (this.state.activeOnly) ? 1 : 0);

        Axios.post(Endpoints.guide.fetchGuidesForCms, formData)
            .then((r) => {
                let tableData = [];
                let tableTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    tableData = resp.data.data;
                    tableTotal = resp.data.count;
                } else {
                    AlertModal.showModal("Error", API.formatError(resp));
                }

                this.setState({
                    guidesNetworkInFlight : false,
                    tableData,
                    tableTotal
                });
            })
            .catch((e) => {
                this.setState({
                    guidesNetworkInFlight : false
                });

                AlertModal.showModal("Error", "An unknown error has occurred. Please reload the page. [DU1000]");
            });
    }

    render() {
        let emptyMessage = undefined;
        if (this.state.guidesNetworkInFlight) {
            emptyMessage = (<LoadingSpinner shown={true} />);
        }

        return (
            <div className={"vendors-landing-component"}>
                <div className={"container"}>
                    <div className={"row screen-title-container"}>
                        <div className={"col-12 col-md-6"}>
                            <ScreenTitle title={"Guides"} showBackButton={false} {...this.props} />
                        </div>
                        <div className={"col-12 col-md-6 screen-actions animated"}>
                            <NavLink className={"btn btn-light me-2"} to={"/categories"}>Manage Categories</NavLink>
                            <button className={"btn btn-light"} onClick={() => this.moveToEditor()}>Add Guide</button>
                        </div>
                    </div>

                    <div className={"app-content animate-screen-did-appear"}>
                        <div className={"row mt-2"}>
                            <div className={"col-12 col-md-6"}>
                                <div>
                                    <input  type="checkbox" name="activeOnly" checked={this.state.activeOnly} onClick={this.handleChange}/> Show active guides&nbsp;&nbsp;&nbsp;
                                    <input  type="checkbox" name="allGuides" checked={this.state.allGuides} onClick={this.handleChange}/> Show all guides
                                </div>
                            </div>

                            <div className={"col-12 col-md-6 text-end"}>
                                <NavLink to={"/guide-sort"} className={"btn btn-light"}>Sort Guides</NavLink>
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                                <TableComponent
                                    className={"alternating clickable small-table"}
                                    data={this.state.tableData}
                                    onRowClick={this.tableRowWasClicked}
                                    emptyMessage={emptyMessage}>
                                    <Column name={"id"} title={"ID"} />
                                    <Column name={"title"} title={"Title"} />
                                </TableComponent>

                                <div className={"ep-table-paginate-container"}>
                                    <div className={"push"} />
                                    <TablePaginator
                                        page={this.state.currentPage}
                                        totalCount={this.state.tableTotal}
                                        pageSize={this.state.tableLimit}
                                        onClick={(page) => this.tablePageDidChange(page)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
import React from "react";
import {StringUtil} from "../data/StringUtil";

export default class BaseComponent extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleChange = this.handleChange.bind(this);
        this.initState({}); // Load a blank object into state to begin
    }

    /**
     * Initialises the state for this component
     * @param state     State Object
     */
    initState(state) {
        this.state = state;
        this.setState(state);
    }

    /**
     * Handler for input items to ensure the value of the input is put into the state
     * @param event     Event from the onChange property
     */
    handleChange(event, callback) {
        const target = event.target;
        const name = target.name;

        let value = target.value;
        if (target.type === "checkbox") {
            value = target.checked;
        }

        this.setState({
            [name] : value
        }, callback);
    }

    getOrDefault = (object, key, defaultValue) => {
        if (object !== null) {
            if (object.hasOwnProperty(key)) {
                return object[key];
            }
        }
        return defaultValue;
    }

    getOrDefaultNotNull = (object, key, defaultValue) => {
        let out = this.getOrDefault(object, key, defaultValue);
        if (out === null) {
            out = defaultValue;
        }
        return out;
    }

    /**
     * Takes in an object and applies it to the current state. Can be prefixed, prefixed items are camel-cased.
     * @param object        Object to extract from
     * @param prefix        Prefix to append. If undefined, no prefix is added, objects are added raw.
     * @param callback      Callback for when state has been updated
     */
    populateObjectIntoState = (object, prefix, callback) => {
        let state = {};

        let keys = Object.keys(object);
        keys.forEach((key) => {
            if (prefix !== undefined) {
                let value = object[key];
                key = prefix + StringUtil.capitalizeFirstLetter(key);
                state[key] = value;
            }
        });

        this.setState(state, callback);
        // console.log(state);
    }

    /**
     * Validate an Object and create Form Data from it
     *
     * @param inObj         Object to validate
     * @param ruleArray     Rules to validate against
     * @returns {{success: boolean}}        Validation Result Bundle
     */
    validateCreateFormData(inObj, ruleArray) {
        var outBundle = {
            success : true
        };

        var formData = new FormData();

        for (var i = 0; i < ruleArray.length; i++) {
            let rules = ruleArray[i];

            let keyName = rules.key;
            let type = rules.type;
            let label = rules.label;
            let postName = rules.postName;
            let optional = rules.optional;
            let defaultValue = rules.default;

            if (keyName === undefined) {
                console.log("Validation rule was passed with no key. Skipping.");
                continue;
            }

            if (type === undefined) {
                type = "string";
            }

            if (optional === undefined) {
                optional = false;
            }

            if (inObj.hasOwnProperty(keyName)) {
                var valid = true;
                var invalidReason = "";

                var value = inObj[keyName];
                if (value === undefined && defaultValue !== undefined) {
                    value = defaultValue;
                }

                if (type !== undefined) {
                    if (type === "int" || type === "integer" || type === "float" || type === "double") {
                        if (type === "int" || type === "integer") {
                            value = parseInt(value);
                        } else if (type === "float" || type === "double") {
                            value = parseFloat(value);
                        }

                        if (isNaN(value)) {
                            valid = false;
                            invalidReason = "Please provide a valid number for " + label;
                        }

                        if (valid && rules.hasOwnProperty("greaterZero")) {
                            if (rules.greaterZero) {
                                if (value <= 0) {
                                    valid = false;
                                    invalidReason = "Please provide a number greater than zero for " + label;
                                }
                            }
                        }

                        if (valid && rules.hasOwnProperty("greaterEqualsZero")) {
                            if (rules.greaterEqualsZero) {
                                if (value < 0) {
                                    valid = false;
                                    invalidReason = "Please provide a number greater or equal to zero for " + label;
                                }
                            }
                        }
                    } else if (type === "string") {
                        var notEmpty = true;
                        if (rules.hasOwnProperty("notEmpty")) {
                            notEmpty = rules.notEmpty
                        }

                        if (notEmpty) {
                            if (value === "" || value == null) {
                                valid = false;
                                invalidReason = "Please provide a " + label;
                            }
                        }
                    } else if (type === "boolean") {
                        if (value) {
                            value = 1;
                        } else {
                            value = 0;
                        }
                    }
                }

                if (valid) {
                    if (postName !== undefined) {
                        formData.append(postName, value);
                    } else {
                        formData.append(keyName, value);
                    }
                } else if (!optional) {
                    outBundle.success = false;
                    outBundle.error = invalidReason;
                    break;
                }
            } else if (!optional) {
                if (defaultValue !== undefined && defaultValue != null && postName !== undefined) {
                    formData.append(postName, defaultValue);
                } else {
                    outBundle.success = false;
                    outBundle.error = "Please provide a " + label;
                    break;
                }
            }
        }

        if (outBundle.success) {
            outBundle.formData = formData;
        }

        return outBundle;
    }

    getUriProperty = (keyName, defaultValue, props) => {
        console.log(props);
        if (props === undefined) {
            props = this.props;
        }

        if (props !== undefined) {
            if (props.match !== undefined) {
                if (props.match.params !== undefined) {
                    if (props.match.params[keyName] !== undefined) {
                        return props.match.params[keyName];
                    }
                }
            }
        }

        return defaultValue;
    }

}
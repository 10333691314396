import React from 'react';

import './AlertModal.css';

export default class AlertModal extends React.Component {

    dismiss = () => {
        if (this.props.dismissCallback !== undefined) {
            this.props.dismissCallback();
        }
    }

    static showModal = (title, message, buttons) => {
        if (window._showModal !== undefined) {
            window._showModal(title, message, buttons);
        }
    }

    static showError = (message) => {
        AlertModal.showModal(
            "Error",
            message
        );
    }

    render() {
        let open = false;
        if (this.props.open !== undefined) {
            open = this.props.open;
        }

        if (open) {
            let icon = [];
            if (this.props.icon !== undefined) {
                icon = (
                    <div className={"alert-modal-dialog-icon"} style={{backgroundImage : "url(" + this.props.icon + ")"}} />
                );
            }

            let title = [];
            if (this.props.title !== undefined) {
                title = (
                    <div className={"alert-modal-dialog-title"}>{this.props.title}</div>
                );
            }

            let message = "";
            if (this.props.message !== undefined) {
                message = this.props.message;
            }

            let buttons = [
                {
                    label : "OK",
                    click : () => {
                        this.dismiss();
                    }
                }
            ];
            if (this.props.buttons !== undefined) {
                buttons = this.props.buttons;
            }

            return (
                <div className={"alert-modal"}>
                    <div className={"spacer"} />

                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"hidden-xs col-md-3 col-lg-4"} />
                            <div className={"col-12 col-md-6 col-lg-4"}>
                                <div className={"alert-modal-dialog"}>
                                    {icon}
                                    {title}
                                    <div className={"alert-modal-dialog-message"}>{message}</div>
                                    <div className={"alert-modal-dialog-buttons"}>
                                        {buttons.map((button) => {
                                            let className = "alert-dialog-button";
                                            if (button.hasOwnProperty("className")) {
                                                className += " " + button.className;
                                            }

                                            return (
                                                <div className={className} onClick={button.click}>{button.label}</div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"spacer"} />
                </div>
            );
        }
        return [];
    }
}
export const DataManager = {
    _callbacks : [],
    setAuthenticationToken : (token) => {
        return DataManager.setItem(DataManager.KEYS.AUTHENTICATION_TOKEN, token);
    },
    getAuthenticationToken : () => {
        return DataManager.getItem(DataManager.KEYS.AUTHENTICATION_TOKEN);
    },
    setUser : (user) => {
        return DataManager.setJsonItem(DataManager.KEYS.USER, user);
    },
    getUser : (user) => {
        return DataManager.getJsonItem(DataManager.KEYS.USER);
    },
    getItem : (keyName, defaultValue) => {
        if (defaultValue === undefined) defaultValue = null;

        let item = localStorage.getItem(keyName);
        if (item != null) {
            return item;
        }
        return defaultValue;
    },
    setItem : (keyName, value) => {
        localStorage.setItem(keyName, value);
        DataManager._triggerCallbacks(keyName, value);
        return true;
    },
    getJsonItem : (keyName) => {
        let item = DataManager.getItem(keyName);
        if (item != null) {
            item = JSON.parse(item);
            return item;
        }
        return null;
    },
    setJsonItem : (keyName, item) => {
        return DataManager.setItem(keyName, JSON.stringify(item));
    },
    clear : () => {
        let keys = [
            DataManager.KEYS.AUTHENTICATION_TOKEN, DataManager.KEYS.USER
        ];
        for (let i = 0; i < keys.length; i++) {
            localStorage.removeItem(keys[i]);
        }
        return true;
    },
    addCallback : (callback) => {
        if (callback instanceof Function) {
            DataManager._callbacks.push(callback);
        }
    },
    removeCallback : (callback) => {
        for (let i = 0; i < DataManager._callbacks.length; i++) {
            if (DataManager._callbacks[i] === callback) {
                delete DataManager._callbacks[i];
                break;
            }
        }
    },
    _triggerCallbacks : (keyName, value) => {
        DataManager._callbacks.forEach((callback) => {
            callback(keyName, value);
        });
    },
    KEYS : {
        AUTHENTICATION_TOKEN : "sessionsapp_authtoken",
        USER : "sessionsapp_user"
    }
}
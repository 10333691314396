import BaseComponent from "../BaseComponent";

import "./HeaderComponent.css";

import logo from "../../assets/certsure/image/niceic_logo.svg";
import {NavLink} from "react-router-dom";

export default class HeaderComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({});
    }

    promptToLogout = (e) => {
        if (e !== undefined) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (window.confirm("Sign out now, are you sure?")) {
            if (window.hasOwnProperty("_sessionsSignOut")) {
                window._sessionsSignOut();
            }
        }
    }

    render() {

        return (
            <div className={"container"}>
                <div className={"header-component"}>
                    <NavLink to={"/"} className={"logo"} style={{backgroundImage : "url(" + logo + ")"}} />
                    <div className={"space"} />
                    <div className={"actions"}>
                        <NavLink to={"/guides"}>Guides</NavLink>
                        <NavLink to={"/message"}>Messages</NavLink>
                        <NavLink to={"/news"}>News</NavLink>
                        <NavLink to={"/links"}>Links</NavLink>
                        <NavLink to={"/users"}>Users</NavLink>
                        <a className={"inverse"} href={"#"} onClick={this.promptToLogout}>Sign Out</a>
                    </div>
                </div>
            </div>
        )

    }

}
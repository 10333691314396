export const Endpoints = {
    auth : {
        login : "auth/login"
    },
    user : {
        getUsers : "user/getUsers",
        getUser : "user/getUser",
        getCSV : "user/getCSV",
        deleteUser : "user/deleteUser",
        submitUser : "user/submitUser",
        submitCMSUser : "user/submitCMSUser"
    },
    messaging : {
        getMessages : "messaging/getMessages",
        broadcastMessage : "messaging/broadcastMessage"
    },
    guide : {
        getGuides : "guide/getGuides",
        fetchGuidesForCms : "guide/fetchGuidesForCms",
        getGuide : "guide/getGuide",
        submitGuide : "guide/submitGuide",
        getCategories : "guide/getCategories",
        submitCategory : "guide/submitCategory",
        submitGuideOrdering : "guide/submitGuideOrdering"
    },
    more : {
        getLatestNewsItems : "more/getLatestNewsItems",
        submitNewsItem : "more/submitNewsItem",
        getMoreLinkItems : "more/getMoreLinkItems",
        submitMoreLinkItem : "more/submitMoreLinkItem",
        submitMoreLinkItemOrdering : "more/submitMoreLinkItemOrdering"
    }
}
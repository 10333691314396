import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const API = (function(axios) {
    return {
        setAuthenticationToken : (token) => {
            if (token !== undefined && token !== null) {
                axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            } else if (token === null) {
                delete axios.defaults.headers.common["Authorization"];
            }
        },
        onAuthFailed : (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback();
                    } else {
                        return status;
                    }
                }
            }
        },
        parse : (resp) => {
            var out = {
                success : false,
                data : null,
                error : "An unknown error has occurred"
            };

            if (resp != null) {
                out.success = resp.data.success;
                out.error = resp.data.error;
                out.data = resp.data.data;
            }

            return out;
        },
        formatError : resp => {
            if (!resp.success) {
                return resp.error.desc + " [" + resp.error.code + "]";
            }
            return "";
        },
        objectToPostData : (object) => {
            let keys = Object.keys(object);
            let formData = new FormData();

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let value = object[key];
                if (typeof value === 'object' && value !== null) {
                    value = JSON.stringify(value);
                }

                formData.append(key, value);
            }

            return formData;
        },
        getAPIUrl : (path, includeAuthToken) => {
            let out = process.env.REACT_APP_BASE_URL + path;
            if (includeAuthToken) {
                out = API.appendAuthTokenToUrl(out);
            }
            return out;
        },
        appendAuthTokenToUrl : (url) => {
            let suffix = {
                at : escape(axios.defaults.headers.common["authtoken"])
            };
            return url + API.createQueryString(suffix);
        },
        createQueryString : (data) => {
            let out = "";
            if (data !== undefined && data != null) {
                for (const [key, value] of Object.entries(data)) {
                    if (out === "") {
                        out = "?"
                    } else {
                        out += "&"
                    }

                    out += key + "=" + escape(value);
                }
            }
            return out;
        }
    }

})(axios);
import ScreenTitle from "../common/ScreenTitle";
import {createRef, useEffect, useRef, useState} from "react";
import {EditorState, ContentState, convertToRaw} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import Axios from "axios";
import {Endpoints} from "../../network/Endpoints";
import AlertModal from "../common/AlertModal";
import {API} from "../../network/API";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import imageCompression from "browser-image-compression";
import Validator from "../../util/Validator";

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {Chronos} from "../../util/Chronos";

export const NewsEditorComponent = (props) => {

    const {id} = props.match.params;

    const [article, setArticle] = useState(null);
    const [articleTitle, setArticleTitle] = useState("");
    const [articlePublished, setArticlePublished] = useState(0);
    const [articleActive, setArticleActive] = useState(0);
    const [articleContent, setArticleContent] = useState("");
    const [articleThumbnail, setArticleThumbnail] = useState(null);

    const [notificationChecked, setNotificationChecked] = useState(false);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [uploadFile, setUploadFile] = useState(null);

    const thumbFileInput = createRef();

    useEffect(() => {
        if (id && id !== "new") {
            fetchArticleFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (article) {
            setArticleTitle(article.title);
            setArticlePublished(article.published);
            setArticleActive(article.active);
            setArticleContent(article.content);
            setArticleThumbnail(process.env.REACT_APP_BASE_URL + article.thumbNailPath);
        }
    }, [article]);

    useEffect(() => {
        if (articleContent) {
            try {
                const contentBlock = htmlToDraft(articleContent);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    setEditorState(EditorState.createWithContent(contentState));
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            setEditorState(EditorState.createEmpty());
        }
    }, [articleContent])

    useEffect(() => {
        if (uploadFile) {
            let reader = new FileReader();
            reader.onload = () => {
                setArticleThumbnail(reader.result);
            };
            reader.readAsDataURL(uploadFile);
        }
    }, [uploadFile]);

    function thumbFileWasRequested() {
        if (thumbFileInput.current) {
            thumbFileInput.current.click();
        }
    }

    function thumbFileDidChange(e) {
        if (e.target.files.length > 0) {
            setUploadFile(e.target.files[0]);
        }
    }

    function fetchArticleFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let data = {
            ids : [id],
            fetchNotificationHistory : true,
            published : 0,
            active : 0
        };

        Axios.post(Endpoints.more.getLatestNewsItems, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setArticle(resp.data.data[0]);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError("An unknown error occurred. Please try again later. [NE1000C]");
            })
    }

    async function submitArticleOverNetwork() {
        if (networkInFlight) return;

        const validationResult = Validator.validateCreateFormData({
            id : article ? article.id : undefined,
            content : draftToHtml(convertToRaw(editorState.getCurrentContent())),
            articleTitle, articlePublished, articleActive
        }, [
            Validator.rule("id", "int", "", "id", true),
            Validator.rule("articleTitle", "string", "Title", "title"),
            Validator.rule("articlePublished", "int", "Published", "published"),
            Validator.rule("articleActive", "int", "Active", "active"),
            Validator.rule("content", "string", "Article Body", "content")
        ]);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        const formData = validationResult.formData;

        if (parseInt(articlePublished) === 1 && parseInt(articleActive) === 1) {
            if (notificationChecked) {
                formData.append("sendNotification", "1");
            }
        }


        if (uploadFile) {
            // Compress thumbnail image now
            const compressedFile = await imageCompression(uploadFile, {
                maxSizeMB : 1, // 1MB
                maxWidthOrHeight : 720, // Max thumb size 720x720
                useWebWorker : true,
                initialQuality : 0.8
            });

            if (compressedFile) {
                formData.append("thumbnailFile", compressedFile);
            }
        }

        setNetworkInFlight(true);

        Axios.post(Endpoints.more.submitNewsItem, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setArticle(resp.data.newsItem);
                    setNotificationChecked(false); // Uncheck Notification box, prevent accidental sends
                    AlertModal.showModal(
                        "Success",
                        "Article has been saved successfully"
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError("An unknown error has occurred. Please try again later. [NIS2000C]")
            })
    }

    // RENDER

    let primaryContent = [];
    if (!networkInFlight) {
        let lastNotificationElem = [];
        if (article && article.hasOwnProperty("notificationHistory")) {
            if (article.notificationHistory.length > 0) {
                let lastSend = article.notificationHistory[0];

                let date = Chronos.withTimestampSeconds(lastSend.dateCreated);

                lastNotificationElem = (
                    <div className={"alert alert-warning"}>
                        A Notification for this article was sent on {date.format("dd/MM/yyyy HH:mm")}
                    </div>
                )
            }
        }

        let notifyUsersElem = [];
        if (parseInt(articlePublished) === 1 && parseInt(articleActive) === 1) {
            // Only show the prompt to notify users when the article is published and active
            notifyUsersElem = (
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <h4>Notify Users</h4>
                                <div>When ticking the box below, a notification will be sent to all users regarding the news article.</div>
                                {lastNotificationElem}
                                <div className={"mt-2"}>
                                    <label><input type={"checkbox"} checked={notificationChecked} onChange={(e) => setNotificationChecked(e.target.checked)} /> Send Notification</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        primaryContent = (
            <>
                <div className={"row"}>

                    <div className={"col-12 col-md-6"}>
                        <div className={"row"}>
                            <div className={"col-12 mt-2"}>
                                <label>Title</label>
                                <input type={"text"} className={"form-control"} value={articleTitle} onChange={(e) => setArticleTitle(e.target.value)} />
                            </div>

                            <div className={"col-12 mt-2"}>
                                <label>Published</label>
                                <select className={"form-select"} value={articlePublished} onChange={(e) => setArticlePublished(e.target.value)}>
                                    <option value={"0"}>Unpublished</option>
                                    <option value={"1"}>Published</option>
                                </select>
                            </div>

                            <div className={"col-12 mt-2"}>
                                <label>Active</label>
                                <select className={"form-select"} value={articleActive} onChange={(e) => setArticleActive(e.target.value)}>
                                    <option value={"0"}>Inactive</option>
                                    <option value={"1"}>Active</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <div className={"row justify-content-center"}>
                            <div className={"col-12 col-md-6"}>
                                <label>Thumbnail</label>
                                <div className={"ratio ratio-16x9 image-preview mt-2"} style={{backgroundImage : "url(" + articleThumbnail + ")"}} />
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12 text-center"}>
                                <button className={"btn btn-light"} onClick={() => thumbFileWasRequested()}>Select Image</button>
                                <div className={"file-hide"}>
                                    <input type={"file"} ref={thumbFileInput} accept={"image/*"} onChange={thumbFileDidChange} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState} />
                            </div>
                        </div>
                    </div>
                </div>

                {notifyUsersElem}

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn btn-light"} onClick={() => submitArticleOverNetwork()}>Save</button>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={"news-list-component"}>
            <div className={"container"}>
                <div className={"row screen-title-container"}>
                    <div className={"col-12"}>
                        <ScreenTitle title={"Article"} showBackButton={true} {...props} />
                    </div>
                </div>

                <div className={"app-content animate-screen-did-appear"}>
                    {primaryContent}
                </div>
            </div>
        </div>
    )

}